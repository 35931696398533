export default function MiniTotoro(props) {
  return (
    <svg
      viewBox="0 0 423.75 565.93"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <style>
        {".__mini-totoro-2{fill:#FEFDEB;}.__mini-totoro-3{fill:#CCDED9;}.__mini-totoro-7{fill:#73A93E;}.__mini-totoro-17{fill:#FFFFFF;}.__mini-totoro-45{fill:#B95D41;}.__mini-totoro-48{fill:#9A5D41;}.__mini-totoro-49{fill:#A3D592;}.__mini-totoro-50{fill:#E5DEC9;stroke:#000000;stroke-width:3.29;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}.__mini-totoro-51{stroke:#000000;stroke-width:3.29;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"}
      </style>
        <g>
          <path class="__mini-totoro-50" d="M226.65,248.38c0,0-0.31-4.95-4.34-7.74c-1.11-0.77-6.53-3.99-13.88-8.25c-1.78-14.93-2.62-45.59,0.96-47.18
            c4.18-1.86,29.03,25.55,43.66,37.85c14.63,12.31,9.29,21.13,9.29,21.13l-0.89,1.36C241.87,242.27,226.65,248.38,226.65,248.38z"/>
          <g>
            <path class="__mini-totoro-45" d="M160.66,270.31L160.66,270.31c8.26,16.79,17.92,29.75,26.05,25.75c0,0-19.82,34.68-21.06,37.47
              c-4.4,9.89-10.36,19.08-14.65,29.1c-1.64,3.83-3.15,7.7-4.56,11.59c-11.35-1.03-32.95-7.61-36.69-42.25
              C104.65,284.61,160.66,270.31,160.66,270.31z"/>
            <path d="M160.67,270.15c4.52,8.03,11.91,23.3,21.34,25.95c1.51,0.34,3.08,0.16,4.46-0.53c0.44-0.23,0.98,0.31,0.71,0.75
              c0,0-10.7,19.23-10.7,19.23c-3,5.59-7.73,13.64-10.42,19.29c-4.5,10.12-10.49,19.32-14.52,29.58c0,0-3.93,10.21-3.93,10.21
              c-0.2,0.54-0.73,0.86-1.28,0.81c-3.17-0.27-6.32-0.88-9.37-1.87c-4.61-1.44-8.87-3.77-12.65-6.8c-1.04-0.97-2.59-2.26-3.49-3.33
              c-10.3-10.93-14.59-30.06-12.54-44.73C111.95,292.77,136.8,276.02,160.67,270.15L160.67,270.15z M160.64,270.48
              c-26.65,9.09-52.23,28.98-49.27,59.93c0.88,9.63,3.29,19.6,8.87,27.57c0.92,1.48,2.35,3.02,3.5,4.33c0.55,0.53,1.87,1.76,2.43,2.3
              c0.99,0.74,2.51,2.03,3.59,2.64c4.98,3.26,10.84,5.16,16.81,5.76l-1.28,0.81l4.12-10.27c4.17-10.21,10.39-19.53,14.98-29.45
              c1.34-2.84,3.75-6.94,5.31-9.72c3.5-6.13,12.89-22.29,16.55-28.57l0.71,0.75C175.79,301.67,164.12,277.99,160.64,270.48
              L160.64,270.48z"/>
          </g>
          <g>
            <path class="__mini-totoro-2" d="M104.34,396.39c9.6-4.33,30.96,11.46,30.96,11.46l1.77,0.54c-2.93,15.29-4.24,30.86-4.01,46.52
              c-0.02,0-0.04,0-0.07,0c-7.26-1.55-21.84-17.96-28.65-27.87C97.53,417.13,94.74,400.72,104.34,396.39z"/>
            <path d="M104.28,396.24c8.56-3.42,24.93,6.6,31.24,10.93c0,0,1.78,0.51,1.78,0.51c0.36,0.1,0.58,0.46,0.52,0.82
              c-1.3,7.67-2.32,15.39-2.87,23.14c-0.56,7.74-0.75,15.5-0.51,23.25c0.08,0.87-0.77,1.61-1.62,1.38c-3.34-0.76-6.09-2.84-8.72-4.87
              c-4.95-4-9.34-8.55-13.42-13.39c-4-4.9-8.17-9.9-10.56-15.84C97.02,414.13,94.9,400.79,104.28,396.24L104.28,396.24z
               M104.41,396.54c-6,2.78-6.39,10.38-5.04,16.05c1.33,5.96,4.29,11.31,8.09,16.06c5.64,7.24,11.69,14.34,18.59,20.4
              c2.19,1.81,4.55,3.73,7.26,4.52c0,0-1.62,1.38-1.62,1.38c0.02-15.67,1.43-31.35,4.66-46.68c0,0,0.52,0.86,0.52,0.86l-1.76-0.58
              C127.75,403.41,113.59,393.03,104.41,396.54L104.41,396.54z"/>
          </g>
          <g>
            <path class="__mini-totoro-2" d="M137.08,408.39c2.22-11.58,5.33-23,9.37-34.16c1.41-3.9,2.92-7.76,4.56-11.59
              c4.28-10.02,10.25-19.21,14.65-29.1c1.24-2.79,21.06-37.47,21.06-37.47c-8.13,4-17.8-8.95-26.05-25.75
              c-10.28-20.93-18.37-47.82-18.54-55.38c-0.31-13.62,6.81-12.7,11.15-12.39c3.14,0.22,35.84,18.64,55.17,29.84
              c7.35,4.26,12.78,7.48,13.88,8.25c4.03,2.79,4.34,7.74,4.34,7.74s15.22-6.11,34.79-2.82c24.04,4.03,54.66,22.23,71.72,83.33
              c22.29,126.33-18.58,143.67-18.58,143.67c1.77,8.86,3.2,17.84,4.09,26.83c0.66,6.64,3.98,18.42-4.28,21.23
              c-11.46,3.91-25.05,2.18-36.69-0.02c-8.58-1.62-16.74-4.89-25.17-7.13c-8.6-2.28-17.31-4.1-26.07-5.68
              c-10.74-1.94-21.52-3.69-32.33-5.2c0.13,0.02-2.15,3.46-2.48,3.78c-1.41,1.39-3.39,2.4-5.11,3.36c-3.97,2.23-8.23,3.98-12.64,5.12
              c-8.75,2.26-18.71,2.18-26.26-3.39c-12.09-8.91-13.01-29.02-14.01-42.6c-0.34-4.66-0.51-9.32-0.58-13.97
              C132.83,439.25,134.15,423.68,137.08,408.39z"/>
            <path d="M136.75,408.33c3.98-20.1,10.4-40.14,20.07-58.25c3.8-6.75,6.93-13.79,10.49-20.64c2.41-4.49,16.01-28.77,18.77-33.73
              l0.94,1c-4.36,2.32-9.29-0.61-12.41-3.63c-3.22-3.04-5.77-6.6-8.14-10.24c-9.19-14.71-15.58-30.88-20.97-47.3
              c-2.04-6.79-4.36-13.6-4.9-20.75c-0.23-8.71,2.56-15.23,12.84-13.88c0,0,0.36,0.06,0.36,0.06c0.43,0.06,0.67,0.2,1.03,0.3
              c3.13,1.2,5.98,2.76,8.93,4.24c15.29,7.97,30.24,16.45,45.2,24.98c0,0,11.22,6.6,11.22,6.6l2.8,1.65c3.3,1.99,5.28,5.7,5.67,9.5
              c0,0-2.75-1.74-2.75-1.74c3.21-1.2,6.29-1.97,9.49-2.61c27.36-5.36,52.99,4.56,71.06,25.46c14.39,16.85,23.13,37.76,29.25,58.81
              c2.28,12.54,4.13,25.17,5.41,37.88c3.11,31.24,4.75,88.68-23.75,108.8c-0.45,0.26-0.76,0.46-1.46,0.78l1.98-3.74
              c1.45,7.15,2.68,14.37,3.59,21.64c0.45,8.93,5.53,23.06-3.48,29.06c-1,0.68-2.15,1.1-3.27,1.46c-4.62,1.46-9.54,2.12-14.28,2.21
              c-15.06,0.23-29.44-3.39-43.43-8.21c-19.63-6.06-42.87-9.42-63.19-13.1c0,0,0.69-4.73,0.69-4.73c3.91,3.26,1.21,2.69,1.93,3.09
              c-0.08,0.25-0.06,0.18-0.1,0.28c-0.07,0.15-0.07,0.14-0.1,0.22l-0.12,0.23l-0.2,0.33l-0.36,0.57l-0.72,1.07l-0.75,1.06l-0.44,0.59
              l-0.21,0.25l-0.12,0.12l-0.04,0.04l-0.07,0.07c-9.4,7.06-22.96,11.32-34.71,9.5c-15.64-2.48-21.9-15.92-23.97-30.07
              C131.21,461.22,131.65,434.38,136.75,408.33L136.75,408.33z M137.4,408.45c-3.69,19.49-4.48,39.39-3,59.13
              c1.36,15.84,2.36,39.71,20.05,45.59c11.89,3.64,25.88-1.11,35.53-8.38c0,0,0.04-0.04,0.04-0.04c0.06-0.07-0.13,0.15-0.06,0.06
              l0.29-0.42l0.65-0.99l0.63-1.01l0.29-0.49l0.12-0.21c0.06-0.12-0.01,0.02-0.01,0.03c-0.03,0.08,0-0.02-0.08,0.21
              c0.72,0.38-1.96-0.23,1.93,3.01l0.69-4.73c16.81,2.52,37.32,4.97,53.73,8.96c10.47,2.27,20.92,6.58,31.2,8.37
              c7,1.28,14,2.19,20.86,2.01c4.49-0.11,8.8-0.75,12.99-2.13c1.41-0.55,2.27-1.3,2.78-2.98c0.79-2.82,0.39-6.21-0.02-9.29
              c-0.55-3.4-0.92-7.27-1.32-10.8c-0.85-7.08-2-14.13-3.38-21.15c-0.32-1.6,0.56-3.11,1.98-3.74c8.03-4.86,12.52-13.97,15.89-22.46
              c12.69-36.86,7.35-79.77,1.41-117.68c-5.54-19.51-14.27-41.65-28.49-57.74c-18.71-21.82-47.28-30.47-74.7-21.36
              c-1.06,0.4-2.25-0.14-2.63-1.21c-0.18-0.63-0.14-1.05-0.33-1.75c-0.31-1.28-0.89-2.55-1.7-3.56c-1.31-1.67-3.08-2.27-4.72-3.37
              c-10.65-6.35-22.51-13.39-33.28-19.41c-10.36-5.69-20.52-11.93-31.33-16.66c-0.24-0.08-0.2-0.08-0.42-0.1
              c-2.35-0.2-5-0.42-6.84,1.19c-1.5,1.36-2.22,4.07-2.38,6.32c-0.41,3.62,0.24,7.13,1.04,10.7c4.75,19.81,16.89,54.94,30.96,69.48
              c2.75,2.68,6.79,5.45,10.62,3.55c0.64-0.3,1.29,0.39,0.94,1c-5.17,8.9-10.31,17.81-15.41,26.75c-2.25,3.99-5.47,9.44-7.3,13.56
              c-4.51,9.26-10.11,17.95-13.93,27.52C144.75,378.42,140.21,393.35,137.4,408.45L137.4,408.45z"/>
          </g>
          <g>
            <path class="__mini-totoro-7" d="M306.44,273.03c7.41-26.92,15.04-54.79,12.94-82.96c-4.48-60.07-37.03-87.59-60.53-124.23
              c0,0-8.05,31.43-47.07,54.96c-39.01,23.53-108.07,22.14-151.1-16.71c-5.68-5.13,4.02-5.97,12.24-6.62
              c18.28-1.45,30.32-12.19,39.49-28.19c6.84-11.92,14.56-23.67,24.99-32.78c9.75-8.52,22.6-12.87,35.37-13.95
              c10.63-0.9,28.48-1.42,34.13,9.69c0,0,38.52-7.33,51.95,12.18c2.4,3.48,3.99,7.82,4.43,13.2c0.45,5.37,14.5,17.83,42.01,57.61
              c12.52,18.11,20.47,42.84,23.39,64.99c3.79,28.76,0.29,61.47-9.01,88.98c-0.3,0.88-5.73,12.59-6.11,12.15
              C313.58,281.35,306.44,273.03,306.44,273.03z"/>
            <path d="M306.28,273.07c7.88-29.1,16.12-59.51,11.96-89.87c-0.32-3.92-1.49-9.9-2.17-13.84c-4.31-20.65-13.32-39.96-25.16-57.36
              c-10.43-15.52-22.46-29.96-32.7-45.74l1.4-0.23c-5.73,18.05-18.25,33.31-32.69,45.24c-2.52,2.12-5.3,4.04-7.95,6
              c-1.94,1.3-6.44,4.23-8.42,5.33c-8.64,5.02-18.13,8.45-27.79,10.9c-34.42,8.17-72.15,3.51-102.97-14.1
              c-5.79-3.29-11.27-7.14-16.42-11.36c0,0-2.53-2.17-2.53-2.17c-0.81-0.72-1.77-1.41-2.54-2.66c-0.36-0.6-0.73-1.53-0.45-2.57
              c0.32-1.01,1.03-1.57,1.6-1.94c3.19-1.76,6.75-1.82,10.17-2.25c0,0,3.3-0.29,3.3-0.29l3.19-0.37l3.15-0.59
              c9.46-2.06,17.76-7.68,23.94-15.07c5.74-6.61,9.44-14.63,14.24-21.94c13.21-20.62,27.81-34.24,53.1-37.06
              c12.49-1.1,30.76-2.37,37.88,10.34c0,0-1.82-0.89-1.82-0.89c6.89-1.17,13.73-1.6,20.69-1.31c15.79,0.53,33.81,6.62,37.1,24.19
              c0.31,1.44,0.43,2.91,0.62,4.36c0.99,3,3.55,5.81,5.48,8.44c8.42,10.62,16.92,21.33,24.85,32.35c3.99,5.52,7.93,11.05,11.75,16.71
              c18.71,28.82,25.57,64.19,23.78,98.15c-1.02,18.7-4.48,37.32-10.53,55.04c-0.59,1.87-1.33,3.02-2.06,4.7
              c-1.4,2.77-2.6,5.66-4.35,8.23c-0.02,0.02-0.06,0.06-0.09,0.08c-0.04,0.02-0.1,0.12-0.34,0.06c-0.24-0.16-0.1-0.08-0.16-0.13
              l-0.05-0.06l-0.41-0.49L306.28,273.07L306.28,273.07z M306.6,272.99l6.68,7.6l0.41,0.47l0.05,0.06c-0.03-0.02,0.14,0.12-0.05-0.02
              c-0.19-0.04-0.19,0.02-0.19,0.01c1.65-2.56,2.8-5.45,4.12-8.2c2.15-4.34,3.48-9.73,4.8-14.41c8.74-34.28,9.77-71.14-0.82-105.11
              c-3.94-12.84-9.33-25.35-16.87-36.48c-3.82-5.57-7.8-11.08-11.81-16.54c-4.02-5.46-8.13-10.85-12.31-16.18
              c-5.67-7.46-12.08-14.42-17.31-22.27c-0.59-0.98-1.23-2.13-1.52-3.36c-0.26-1.49-0.32-2.99-0.65-4.46
              c-0.93-4.68-3.2-9.07-6.72-12.31c-7.29-6.71-17.55-8.62-27.27-9.13c-6.63-0.28-13.41,0.12-19.91,1.2
              c-0.74,0.12-1.46-0.24-1.81-0.87c-6.14-11-23.69-9.55-34.52-8.66c-24.11,2.47-38.34,15.76-51.01,35.41
              c-4.87,7.35-8.7,15.47-14.58,22.17c-5.83,6.86-13.49,12.41-22.2,14.97c-3.14,0.92-6.74,1.56-9.99,1.84
              c-3.93,0.44-8.78,0.37-12.32,2.1c-0.34,0.21-0.5,0.43-0.52,0.49c-0.01,0.04-0.01,0.24,0.19,0.57c0.37,0.65,1.19,1.34,2.03,2.06
              c5.71,5.03,11.91,9.54,18.5,13.32c30.25,17.45,67.38,22.16,101.28,14.26c9.5-2.36,18.83-5.69,27.33-10.59
              c4.44-2.52,9.54-6.01,13.6-9.07C238.58,100,252,84.31,258.11,65.63c0.15-0.62,1.07-0.77,1.39-0.2
              c10.08,15.74,22.03,30.24,32.43,45.89c10.4,15.63,18.91,32.7,23.38,51c1.09,4.05,2.16,9.64,2.81,13.81
              c0.47,2.13,0.69,4.83,0.92,6.98C323.01,213.58,314.77,243.9,306.6,272.99L306.6,272.99z"/>
          </g>
          <g>
            <path class="__mini-totoro-17" d="M314.13,299.87c-1.88-0.69-3.98-1.02-5.88-0.39c-2.37,0.79-4.05,3-4.78,5.39c-0.73,2.38-0.64,4.94-0.39,7.42
              c0.92,9.15,4.37,18.65,11.88,23.97c3.79,2.69,8.11,2.92,11.5-0.65c3.41-3.59,2.9-9.24,2.27-13.76
              C327.41,312.62,323.57,303.32,314.13,299.87z"/>
            <path d="M314.07,300.02c-4.36-1.85-8.68-0.17-10.11,4.44c-1.17,4.06-0.19,8.45,0.65,12.5c1.5,6.61,4.47,13.07,9.81,17.34
              c7.16,6.03,13.77,0.64,13.02-7.87C326.78,316.53,324.54,304.02,314.07,300.02L314.07,300.02z M314.19,299.71
              c11.02,3.64,16.34,15.64,16.68,26.6c0.06,2.28-0.15,4.7-1.09,7.02c-1.86,4.73-7.62,7.75-12.45,5.65c-2.18-0.8-4.06-2.38-5.71-3.95
              c-4.78-4.73-7.41-11.28-8.61-17.74c-0.63-4.31-1.32-8.88,0.31-13.07C305.15,299.59,309.65,297.9,314.19,299.71L314.19,299.71z"/>
          </g>
          <path class="__mini-totoro-51" d="M305.64,323.54c-1.48-4.83,2.22-9.79,8.22-5.05c3.93,2.93,6.7,7.37,7.61,12.18c0.22,1.18,0.33,2.46-0.2,3.54
            c-0.85,1.76-3.16,2.43-5.05,1.92c-1.89-0.51-2.61-0.91-4.45-2.61C311.77,333.52,307.12,328.37,305.64,323.54z"/>
          <path class="__mini-totoro-52" d="M194.14,502.59c0,0-15.79-3.41-23.84-15.17"/>
          <g>
            <path class="__mini-totoro-17" d="M252.61,305.62c-8.66-3.39-19.35-2.88-24.8,5.21c-0.81,1.21-1.32,2.6-1.63,4.02
              c-0.97,4.53,0.12,9.29,2.03,13.51c4.06,8.95,13.93,19.26,24.14,20.42c8.7,0.99,14.69-4.94,16.42-13.08
              c2.02-9.47-1.11-19.3-8.55-25.61C258.09,308.27,255.46,306.73,252.61,305.62z"/>
            <path d="M252.55,305.77c-7.7-3.19-18.16-2.84-23.44,4.36c-5.21,7.04-1.35,16.78,3.19,23.21c4.83,6.64,11.63,12.77,19.92,13.93
              c8.69,1.19,14.49-6.16,15.33-14.23c0.38-2.69,0.38-5.45-0.06-8.16C266.32,316.47,260.57,308.84,252.55,305.77L252.55,305.77z
               M252.67,305.47c8.39,3.01,15.3,10.1,17.49,18.86c1.47,5.98,0.98,12.5-2.06,18c-3.11,5.73-9.85,9.04-16.26,7.84
              c-9.09-1.55-16.31-8.32-21.3-15.61c-4.4-6.63-7.78-15.77-3.73-23.35C232.13,302.46,244.02,302.08,252.67,305.47L252.67,305.47z"/>
          </g>
          <path class="__mini-totoro-51" d="M230.5,330.32c-2.27-2.98,2.23-4.98,4.36-5.35c3.69-0.64,7.94-0.13,11.15,1.89c2.96,1.86,5.11,5.09,5.34,8.58
            c0.2,2.94-1.17,7.23-4.24,8.26c-1.54,0.52-3.26,0.14-4.68-0.65c-2.37-1.32-4.49-3.59-6.37-5.52c-2.68-2.75-5.44-5.76-6.1-9.54"/>
          <path class="__mini-totoro-48" d="M147.03,375.96c-11.35-1.03-32.95-7.61-36.69-42.25c-1.97-18.23,5.12-31.54,14.58-41.05
            c1.19,5.57,2.65,12.6,4.46,21.54c4.73,23.39,21.82,26.58,34.75,25.39c-4.08,8.28-8.9,16.23-12.55,24.77
            C149.95,368.2,148.44,372.07,147.03,375.96z"/>
          <path class="__mini-totoro-49" d="M237.55,34.84c-5.25-2.29-40.26-7.22-55.79,8.24c-12.74,12.68-17.96,37.32-33.67,50.86
            c-7.1,6.12-11.13,8.61-19.74,12.35c0,0,12.54,14.82,76.17-54.38C220.3,34.24,233.02,34.1,237.55,34.84z"/>
          <path class="__mini-totoro-49" d="M201.26,28.34c0,0-16.18-12.96-49.12,1.39c-30.08,13.11-39.36,64.21-66.78,69.13c0,0,17.19,22.25,80.83-46.95
            C181.99,34.24,195.69,24.62,201.26,28.34z"/>
          <g>
            <path d="M206.06,33.23c-23.1,1.5-29.73,18.71-40.56,35.72c-7.86,11.3-16.61,22.48-28.35,30.02c-9.27,5.96-21.06,9.7-31.91,6.43
              c-0.19-0.05-0.12-0.35,0.07-0.32c8.12,0.94,16.11-0.98,23.2-4.75c14.25-7.6,25.57-19.8,35.03-32.73
              c7.77-11.02,13.85-24.63,26.56-30.92c4.97-2.46,10.47-3.73,15.96-3.78c0.09,0,0.17,0.07,0.17,0.16
              C206.21,33.15,206.14,33.23,206.06,33.23L206.06,33.23z"/>
          </g>
        </g>
    </svg>
  );
}
