import { resetAccessToken } from "../utils/api";
import Cookies from 'js-cookie';

const { createContext } = require("react");

const AppContext = createContext();

export const APP_INITIAL_STATE = {
  currentUser : null,
  accessToken : null
};

export const appReducer = (draft, action) => {
  switch (action.type) {
    case 'accessToken/set':
      const accessToken = action.payload;

      draft.accessToken = accessToken;
      resetAccessToken(accessToken);

      Cookies.set('leonardo__api_access_token', accessToken, { expires: 365 });
      break;

    case 'currentUser/set':
      draft.currentUser = action.payload;
      draft.loggedIn = true;
      break;

    case 'currentUser/delete':
      draft.currentUser = null;
      draft.accessToken = null;

      Cookies.remove('leonardo__api_access_token');
      resetAccessToken(null);

      break;

    default:
      console.warn("Non-existing action type:", action.type);
  }
}

export default AppContext;
