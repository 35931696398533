//import logo from './logo.svg';
//import './App.css';
import { Routes, Route } from "react-router-dom";
import AppContext, { appReducer, APP_INITIAL_STATE } from "./store/AppContext";
import { useImmerReducer } from "use-immer";

import LandingPage from "./routes/LandingPage/LandingPage";
import ClaimGifts from "./routes/ClaimGifts/ClaimGifts";

function App() {
  const [state, dispatch] = useImmerReducer(appReducer, APP_INITIAL_STATE);

  return (
    <AppContext.Provider value={[state, dispatch]}>
      <Routes>
        <Route path='/:identifier' element={<ClaimGifts />} />
        <Route path='*' element={<LandingPage />} />
      </Routes>
    </AppContext.Provider>
  );
}

export default App;
