//import { useContext, useEffect } from 'react';
//import { Link, useNavigate } from 'react-router-dom';
//import AppContext from '../../store/AppContext';

import SadTotoro from '../../icons/SadTotoro';

import './LandingPage.scss';

export default function LandingPage(props) {
  return (
    <div className='__landing-page'>
      <div className='header'>
        Registro De Regalos Para Leo
      </div>
      <div className='content'>
        <div className='text'>
          ¡Hola! Para poder acceder, tienes que usar el link personalizado que Ale y Ester te enviaron por WhatsApp.
        </div>
        <div className='totoro'>
          <SadTotoro />
        </div>
      </div>
    </div>
  );
}
