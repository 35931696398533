import axios from 'axios';
import Cookies from 'js-cookie';

export let api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { Authorization: `Bearer ${Cookies.get('leonardo__api_access_token')}` }
});

export function resetAccessToken(token) {
  //  instance.defaults.headers.common['Authorization'] = AUTH_TOKEN;
  //! it seems there is a bug that even the default axios instance will show the headers!!
  // strofeApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  
  api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { Authorization: `Bearer ${token}` }
  });
}

export function authorizationHeader() {
  return {
    headers: {
      Authorization: `Bearer ${Cookies.get('leonardo__api_access_token')}`
    }
  }
}
