import { useContext, useEffect, useState } from 'react';
import { api } from '../../utils/api';
import AppContext from '../../store/AppContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useImmer } from 'use-immer';
import Cookies from 'js-cookie';
import classNames from 'classnames';
import { ReactComponent as CheckedIcon } from '../../icons/CheckedIcon.svg';
import MiniTotoro from '../../icons/MiniTotoro';
import UmbrellaTotoro from '../../icons/UmbrellaTotoro';
import SittingTotoro from '../../icons/SittingTotoro';
import Modal from 'react-bootstrap/Modal';
import Watch from '../../icons/Watch.tsx';


import './ClaimGifts.scss';

export default function ClaimGifts(props) {
  const { identifier } = useParams();

  const [state] = useContext(AppContext);
  const { currentUser } = state;
  const [, dispatch] = useContext(AppContext);
  const [gifts, setGifts] = useImmer([]);
  const navigate = useNavigate();
  const [showSuccess, setShowSuccess]                 = useState(false);
  const [showFailure, setShowFailure]                 = useState(false);
  const [showRenounceFailure, setShowRenounceFailure] = useState(false);
  const [selectedGift, setSelectedGift]               = useState('');
  const [loading, setLoading]                         = useState(false);

  // If currentUser is not set, log the user in
  useEffect(() => {
    const login = async () => {
      try {
        const { data } = await api.post('/sessions', {
          client_id: process.env.REACT_APP_API_CLIENT_ID,
          session: { identifier }
        });
        await dispatch({ type: 'accessToken/set', payload: data.access_token });

        return data.access_token;
      }
      catch {
        // Send user to error page
        navigate('/');
      }
    }

    const fetchCurrentUser = async (accessToken) => {
      try {
        const { data } = await api.get('/users/me', { headers: {
          Authorization: `Bearer ${accessToken}`
        }});
        dispatch({ type: 'currentUser/set', payload: data });

        return data.identifier;
      }
      catch {
        // Send user to error page
        navigate('/');
      }
    }

    const startSession = async () => {
      // Login and fetch current user
      if ( Cookies.get('leonardo__api_access_token') === undefined ) {
        const accessToken = await login();
        await fetchCurrentUser(accessToken);
      }
      // Just fetch current user 
      else {
        const loadedIdentifier = await fetchCurrentUser(Cookies.get('leonardo__api_access_token'));

        // If the loaded user doesn't match the identifier, login and load again
        if ( loadedIdentifier !== identifier ){
          const accessToken = await login();
          await fetchCurrentUser(accessToken);
        }
      }
    }    
    startSession();
  }, [dispatch, identifier, navigate]);

  useEffect(() => {
    const fetchGifts = async () => {
      const { data } = await api.get('/gifts?event_id=1');
      setGifts(data);
    }
    if ( currentUser !== null ){
      fetchGifts();
    }
  }, [currentUser, setGifts]);

  const claim = async (index) => {
    if (loading){
      return;
    }
    setLoading(true);

    setGifts(draft => {
      draft[index].loading = true;
    });

    try {
      await api.put(`/gifts/${gifts[index].id}/claim`);
      setSelectedGift(gifts[index].name);
      setShowSuccess(true);
      setGifts(draft => {
        draft[index].loading = false;
        draft[index].claimed_by = currentUser.id;
      });
    }
    catch {
      setShowFailure(true);
      setGifts(draft => {
        draft[index].loading = false;
        draft[index].claimed_by = -1;
      });
    }
    setLoading(false);
  }

  const renounce = async (index) => {
    if (loading){
      return;
    }
    setLoading(true);

    setGifts(draft => {
      draft[index].loading = true;
      draft[index].claimed_by = null;
    });

    try {
      await api.put(`/gifts/${gifts[index].id}/renounce`);
      setGifts(draft => {
        draft[index].loading = false;
      });
    } catch {
      setGifts(draft => {
        draft[index].loading = false;
        draft[index].claimed_by = currentUser.id;
      });
      setShowRenounceFailure(true);
    }
    setLoading(false);
  }

  return (
    <div className='__claim-gifts'>
      <div className='header'>
        Registro De Regalos Para Leo
      </div>
      <div className='content'>
        <div className='summary'>
          {currentUser &&
            <div className='snippet'>
              <b>¡Hola {currentUser.name}!</b><br />
              Hicimos esta lista de sugerencias para que los invitados puedan elegir un regalo a su gusto y con la mayor flexibilidad posible, aunque no es necesario que compren ningún regalo. Si quieren darle a Leo algo que calza solo tienen que marcar el check, para evitar que otras personas le den lo mismo. Si algo sale en color gris es porque ya alguien más eligió ese regalo.<br />
              Si quieren darle algo que no está en la lista o si tienen cosas que le quieran heredar a Leo, eso nos encanta. No es necesario que consigan cosas nuevas, nos gusta la economía circular.
            </div>
          }
          <div className='totoro'>
            <SittingTotoro />
          </div>
        </div>

        <div className='gift-table'>
          <div className='top-delimiter'></div>
          <div className='gift-row gift-row-header'>
            <div className='status'></div>
            <div className='name'>Accesorio</div>
          </div>
          {gifts.map((gift, index) => (
            <div key={index} className={classNames('gift-row', { claimed: gift.claimed_by === currentUser.id }, { swiped: gift.claimed_by !== null && gift.claimed_by !== currentUser.id })}>
              <div className='status'>
                {gift.loading === true &&
                  <Watch
                    height="40"
                    width="40"
                    radius="48"
                    color="var(--green)"
                    ariaLabel="watch-loading"
                    wrapperStyle={{}}
                    wrapperClass="loading"
                    strokeWidth="10"
                    visible={true}
                  />
                }
                {!gift.loading && gift.claimed_by === null && <div className='checkit' onClick={() => claim(index)}></div>}
                {!gift.loading && gift.claimed_by === currentUser.id && <CheckedIcon className='uncheckit' onClick={() => renounce(index)} />}
              </div>
              <div className='name'>
                <div>{gift.name}</div>
                <div className='comment'>{gift.comment}</div>
              </div>
            </div>
          ))}
          <div className='bottom-delimiter'></div>
        </div>
      </div>

      <Modal className='__modal __success-modal modal-lg' show={showSuccess}>
        <Modal.Body>
          <span className="close-button" onClick={() => setShowSuccess(false)}>X</span>
          <div className="content">
            <div>Has seleccionado "{selectedGift}" con éxito. ¡Muchas gracias!</div>
            <MiniTotoro />
          </div>
        </Modal.Body>
      </Modal>

      <Modal className='__modal __failure-modal modal-lg' show={showFailure}>
        <Modal.Body>
          <span className="close-button" onClick={() => setShowFailure(false)}>X</span>
          <div className="content">
            <div>Lo siento, alguien más eligió el regalo que querías, puedes hacer otra selección.</div>
            <UmbrellaTotoro />
          </div>
        </Modal.Body>
      </Modal>

      <Modal className='__modal __failure-modal modal-lg' show={showRenounceFailure}>
        <Modal.Body>
          <span className="close-button" onClick={() => setShowRenounceFailure(false)}>X</span>
          <div className="content">
            <div>Lo siento, algo ocurrió con la conexión mientras intentabas cancelar este regalo.</div>
            <UmbrellaTotoro />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}