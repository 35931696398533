export default function UmbrellaTotoro(props) {
  return (
    <svg
      viewBox="0 0 442.6 565.9"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <style>
        {".__umbrella-totoro-0{fill:#73833E;stroke:#000000;stroke-width:0.9315;stroke-miterlimit:10;}  .__umbrella-totoro-1{fill:#676654;stroke:#000000;stroke-width:1.8631;stroke-miterlimit:10;}  .__umbrella-totoro-2{fill:none;stroke:#000000;stroke-width:1.9648;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}  .__umbrella-totoro-3{fill:#FEFDEB;stroke:#000000;stroke-width:1.9648;stroke-miterlimit:10;}  .__umbrella-totoro-4{stroke:#000000;stroke-width:1.9648;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}  .__umbrella-totoro-5{stroke:#000000;stroke-width:1.9648;stroke-miterlimit:10;}  .__umbrella-totoro-6{fill:#676654;stroke:#000000;stroke-width:1.9648;stroke-miterlimit:10;}  .__umbrella-totoro-7{fill:#6A6957;}  .__umbrella-totoro-8{stroke:#000000;stroke-width:0.9315;stroke-miterlimit:10;}  .__umbrella-totoro-9{fill:#FFFFFF;stroke:#000000;stroke-width:0.8368;stroke-miterlimit:10;}  .__umbrella-totoro-10{fill:#FFFFFF;stroke:#000000;stroke-width:0.9315;stroke-miterlimit:10;}  .__umbrella-totoro-11{opacity:0.28;fill:#606060;enable-background:new    ;}  .__umbrella-totoro-12{fill:#73A93E;}  .__umbrella-totoro-13{fill:none;stroke:#000000;stroke-width:0.9315;stroke-miterlimit:10;}  .__umbrella-totoro-14{fill:none;stroke:#000000;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"}
      </style>
        <g>
          <path className="__umbrella-totoro-0" d="M348.5,127.3c-1.9-1-3.9-2.1-6.2-3.2c-14.1-6.2-24.9-3.7-24.9-3.7c-37.4-15.3-72.1-0.2-72.1-0.2
            c-45.8-20.3-98.9,0-98.9,0c-23.7-1.3-40,9.2-40,9.2s38.4-0.9,51,7.5c0,0,35.8-1.8,57.2,6.3c0,0,37.5-15.1,92.9,0
            c0,0,14.2-12.3,50.9-9.3c-0.8-0.2-1.8-0.9-3.2-1.9C353,130.2,350.7,128.6,348.5,127.3z"/>
          <path className="__umbrella-totoro-1" d="M338.3,419.8l4.2-41.2l-0.2-9.5c0,0-3-7.3-3-9.7s3-0.2,3-0.2s0.5-17.4-3.1-19.5l-1.1-15.2
            c0,0-15.5-19.8-15.5-21.1s-1.3-5.5-3.9-8.8s-7.2-3.7-7.2-3.7s-5.4-13.4-9.4-13.6s-13.9-6.1-15.7-9c-1.9-2.9-17.6-4.8-17.6-4.8
            s-15.2-8.4-24.2-6.6l5.1-3.1c0,0-22.3-1.1-26.6,3.1c0,0-0.7-7.2-15.5-5.7s-15.7,4.9-15.7,4.9l-12.4-1.6l1.1,3.7l-13.7,0.2l0.7,3.7
            c0,0-37.9,13-47.2,29l4.2,0.9c0,0-11.7,5.9-15,12.3l5.9-1.3c0,0-12.9,8.3-12.9,16.5l3.7-0.4c0,0-9.8,17.6-14.2,32.5
            c-4.4,14.9-1.3,37.3-1.8,39.5c-0.6,2.2-5,8.3-4.6,9.9c0.4,1.6,4.4,1.6,4.4,1.6l-2.1,18l-2.8-1.4l-13.5-6.7c0,0-7.4-7.7-6.7-13.6
            c0.7-5.9-5.9-15.8-4.8-16.9c1.1-1.1-2.5-37.8-0.1-39.6c2.3-1.8,4.9-48.1,17.1-55.1c0,0,12.9-31.2,20.7-34.1
            c7.8-2.9,19.2-15.1,19.6-16.9s0-25,15.9-39.3c0,0,3.1-4.4,3.7-10.3c0.6-5.9,8.5-5.9,8.5-5.9l17-11.9c0,0-10.5-1.3-8.9-3.1
            c1.7-1.8,8.9-2.9,8.9-4c0-1.1,0-3.5,0-3.5l-9.8-0.9l3.3-4.8c0,0-7.8-10.5-5.4-24.8c2.4-14.3,7-37.5,17.2-37.8
            c7-0.2,11.5,7.1,13.4,13.6s1.1,13.1,3.1,15.4c2,2.4,6.8,15,4.1,20.9c0,0-1.7,2.4-0.9,4s0.6,4-1.7,4.9c-2.2,0.9,0,1.6,0,1.6
            s3.3,1.1,1.5,4.9c-1.9,3.9,5.4-1.6,10.7-1.6s10.9,0.9,13.5-2s12.6-3.7,16.1-3.1s10.7,2.2,14.1,5.9s13.1,0.6,13.1,0.6s0-5.7,3.9-5.7
            s3-1.8,3-1.8s-17.9-20.9,17.7-56.2c0,0,6.5-6.1,8.9,4c0,0,9.2,26.2,2.8,43.9s-13.3,11.8-13.3,11.8s5.7,5.7,5.7,7.5
            s-3.9,3.7-3.9,3.7l-3.1,0.4v3.5c0,0,8.5,2.9,18.3,25.9c0,0,4.2,8.4,10,13.6c5.7,5.1,4.2,11,5.4,13.6s2.8,11.2,2.8,11.2
            s6.5,1.8,12.4,10.3s12.9,16.9,12.4,20.7c-0.6,3.9-0.6,3.9-0.6,3.9s11.8,6.1,12.2,10.3s20.9,36.5,20.2,57.3c0,0,1.1,6.6-1.9,11
            c0,0,0.4,7.5,0.9,10.5c-9.6,26.2-2.7,61-13.1,87c-3.8,9.4-9.5,17.9-15.5,26c-21,28.1-23.6,54-56.6,66.4c-3.4,1.3-52.5,0.2-58.8-0.8
            s-6.6-3.7-8.2-9.8c-1.8,5.4,1.7,5.6-3.8,7.5s-25-0.8-30.8-0.7c-16,0.4-34.6-0.4-44.2-13.1c-2.9-3.8-4.7-8.6-8.3-11.7
            c-4.1-3.5-9.7-4.6-14.4-7.2c-10.1-5.5-14.9-17.1-18.4-28c-5.3-16.5-9.3-33.4-11.9-50.5"/>
          <path className="__umbrella-totoro-2" d="M104.1,289c-1.4,3.9-2.9,7.7-4.4,11.6c-0.8,2.1-1.6,4.2-2.2,6.4c-0.8,2.9-1.2,6-1.6,9
            c-0.5,4.1-1.1,8.2-1.6,12.3c0.7-1.3,1.7-2.4,3-3.2c0.1,3.7,0,7.5-0.2,11.2"/>
          <path className="__umbrella-totoro-2" d="M332.5,284.7c-0.2,5.3,0.3,10.5,1.5,15.7c0.6,2.8,1.4,5.6,1.8,8.5c0.3,2.1,0.4,4.2,0.4,6.3
            c0,1.5,0.1,2.9,0.1,4.4"/>
          <path className="__umbrella-totoro-2" d="M327.6,297.6c1.1,4.6,2,9.2,2.7,13.9"/>
          <path className="__umbrella-totoro-3" d="M185.6,510.7c0.6-0.6,30.1,7.5,30.9,7.4c5.3-1,21-7.4,26.3-8.4c3.4-0.6,15.3,6.7,78.8-49.5
            c5.1-4.5,6.8-11.9,8.5-18.5c1.7-6.7,3-13.5,5.8-19.8c0.5-1,0.9-2,1.4-2.9l4.2-41.5l-0.2-9.6c0,0-3-7.4-3-9.8s3-0.2,3-0.2
            s0.5-17.6-3.1-19.6l-1.1-15.4c0,0-15.5-20-15.5-21.3c0-1.3-1.3-5.5-3.9-8.9c-2.6-3.3-7.2-3.7-7.2-3.7s-5.4-13.5-9.4-13.7
            s-13.9-6.1-15.7-9.1s-17.6-4.8-17.6-4.8s-15.2-8.5-24.2-6.7l5.1-3.1c0,0-22.3-1.1-26.6,3.1c0,0-0.7-7.2-15.5-5.7
            c-14.8,1.5-15.7,4.9-15.7,4.9l-12.4-1.6l1.1,3.7l-13.7,0.2l0.7,3.7c0,0-37.9,13.1-47.2,29.2l4.2,0.9c0,0-11.7,5.9-15,12.4l5.9-1.3
            c0,0-12.9,8.3-12.9,16.6l3.7-0.4c0,0-9.8,17.8-14.2,32.7c-4.4,15-1.3,37.5-1.8,39.8c-0.6,2.2-5,8.3-4.6,10c0.4,1.7,4.4,1.7,4.4,1.7
            l-2,18.1c0,0-0.6,9.8-0.6,14c0.3-0.4,0.7-0.8,1.2-1.1c-0.1,3.1,0.2,6.1,0.9,9.1c1-2.4,2-4.9,3-7.3c1,6.5,2,13,2.9,19.5
            c0.5-1.3,1-2.6,1.5-3.8c2.1,5.6,3.8,11.3,5.1,17c0.5-1.5,1-3,1.5-4.5c0.1-0.4,0.3-0.8,0.4-1.2c0.7,2,1.2,5.2,2.1,7.1
            c0.7,1.4,1.4,2.7,2.5,3.7c1,1,2.4,1.7,3.8,1.7c0.1,0,0.2,0,0.3,0c13.4,9.2,14.1,10,28.7,17.2C156.2,499.1,168,512.8,185.6,510.7z"
            />
          <path className="__umbrella-totoro-4" d="M215.4,201.4c-0.3,0-0.6,0-0.9-0.1c-0.7-0.3-1-1.2-0.7-1.9c0.3-0.7,0.9-1.2,1.6-1.5c1.2-0.5,2.5-0.6,3.8-0.7
            c4.3-0.2,8.7-0.3,12.8,1c0.7,0.2,1.5,0.8,1.2,1.4c-0.1,0.4-0.5,0.6-0.9,0.7c-1.9,0.6-4.1,0.4-6,1.1c-0.2,0.1-0.4,0.2-0.5,0.4
            c-0.1,0.2,0,0.4-0.1,0.5c-0.1,0.3-0.5,0.4-0.8,0.4c-0.7,0.1-2.4,0.4-3,0c-0.4-0.3-0.1-0.9-0.7-1.2
            C220.2,200.7,216.7,201.3,215.4,201.4z"/>
          <path className="__umbrella-totoro-2" d="M211.1,195c7.4-2.9,15.3-4.2,23.2-4.1"/>
          <path className="__umbrella-totoro-2" d="M235.7,194.7c1.3,0,2.7,0,4,0"/>
          <path className="__umbrella-totoro-2" d="M217.8,189.4c-4.8,0.3-9.9,0.7-13.6,3.7c-1,0.8-1.9,1.9-1.9,3.1"/>
          <g>
            <path className="__umbrella-totoro-5" d="M313.3,195.4c-2.1,0.6-4.1,1.3-6.2,1.9c-4.8,1.3-9.9,1.9-14.1,4.4c-0.7,0.4-1.5,0.9-1.9,1.7
              c-0.4,0.7-0.4,1.8,0.1,2.4c0.5,0.5,1.3,0.6,2,0.6c4.4,0.1,8.7-1.5,12.7-3.2c3.9-1.7,7.7-3.5,11.5-5.5c1.6-0.8,3.1-1.7,4.8-2.4
              c2.1-0.9,4.3-1.5,6.5-2.1c1.5-0.4,4.3-0.5,5.5-1.7c-3.2-0.3-7.2,0.7-10.4,1.3C320.3,193.4,316.8,194.3,313.3,195.4z"/>
            <path className="__umbrella-totoro-5" d="M298.3,213.1c2.1,0.6,4.3,0.5,6.4,0.4c7.1-0.4,14.2-0.7,21.4-1c0.1,0,0.2,0,0.3-0.1s-0.2-0.2-0.2-0.1
              c3.6,0.1,7.3,0.2,11,0.3c3.7,0.1,7.4,0.2,11-0.7c-9.6,0.6-19.1,0.6-28.7,0.2c-2.2-0.1-4.4-0.2-6.6-0.4c-3.2-0.2-6.3-0.4-9.5-0.6
              c-1.1-0.1-2.5-0.4-3.6,0C299.2,211.3,297,212.8,298.3,213.1z"/>
            <path className="__umbrella-totoro-5" d="M302.9,223c11.9,1.2,24.1,1.2,35.6,4.5c5.8,1.7,11.2,4.1,16.7,6.6c0.1-0.8-0.8-1.3-1.5-1.6
              c-15-6.3-30.8-10.7-46.9-13.1c-1.6-0.2-5.6-1.4-6.9,0C297.9,221.5,301.4,222.9,302.9,223z"/>
            <path className="__umbrella-totoro-5" d="M107.8,199.1c-7-1.7-14.1-3.9-21.2-2.8c14.9,4.9,30.2,8.6,45.7,11c2.5,0.4,5,0.7,7.6,0.8
              c0.8,0,3.4-0.2,2.5-1.5c-0.5-0.7-3.9-1.1-4.7-1.3c-3.3-0.8-6.6-1.5-10-2.1C121,201.8,114.4,200.6,107.8,199.1z"/>
            <path className="__umbrella-totoro-5" d="M85.6,211.9c12.3,3.8,24.9,6.8,37.6,8.8c3,0.5,6,0.9,9,0.5c2.6-0.4,4.3-3.6,1.1-4.4c-1.2-0.3-2.8-0.1-4.1-0.2
              c-2-0.2-4-0.4-6-0.7c-8-1-16-2.3-24.1-3.2c-2.2-0.2-4.5-0.4-6.7-0.6C90.3,212.1,87.5,212.5,85.6,211.9z"/>
            <path className="__umbrella-totoro-5" d="M93.5,228.9c5.7,1.1,11.3,2.1,17,3.1c2.7,0.5,5.5,1,8.3,1.1c1.1,0,2.1,0,3.1-0.4c0.8-0.3,2.1-1.1,1.4-1.9
              c-0.4-0.6-3.1-0.5-3.8-0.6c-2.9-0.3-5.8-0.6-8.7-0.8C105.1,228.9,99.3,228.8,93.5,228.9z"/>
          </g>
          <g>
            <path className="__umbrella-totoro-6" d="M258.5,293.7c0.5,0.5,1.2,0.7,2,0.7c3.9,0.1,6.9-4,10.7-4.4c2.6-0.3,5.1,1.1,7.3,2.4c5,3,9.9,6.3,15.4,8.2
              c1,0.3,2.2,0.6,2.9-0.1c0.6-0.5,0.7-1.5,0.4-2.3c-0.3-0.8-0.9-1.4-1.4-1.9c-4.3-4.5-8.7-8.9-13-13.4c-2.1-2.1-4.5-4.4-7.4-4.5
              c-2,0-3.9,1-5.5,2.1C267.6,282,255.3,290.2,258.5,293.7z"/>
            <path className="__umbrella-totoro-6" d="M282.8,319.3c-1.1,1-2.1,2.2-2.1,3.7c0,1.4,1.1,2.7,2.3,3.3c1.3,0.6,2.7,0.8,4.2,0.8c2.8,0.1,5.6,0,8.4-0.5
              c1-0.1,1.9-0.3,2.9-0.1c1,0.2,1.9,0.9,2.7,1.5c2.9,2,6.1,3.5,9.5,4.5c1.1,0.3,2.6,0.5,3.2-0.5c0.2-0.4,0.3-0.9,0.3-1.3
              c0.1-2.6,0.2-5.3-0.6-7.8c-1.3-3.7-4.5-6.5-8.1-8c-3.4-1.5-8.8-3.1-12.4-2.1C289.2,314,285.6,316.6,282.8,319.3z"/>
            <path className="__umbrella-totoro-6" d="M228.3,319.6c1.4-0.6,2.5-1.6,3.8-2.3c4.3-2.5,9.8-2.6,14.2-0.3c1.9,1,3.7,2.5,5.8,3.2
              c2.1,0.7,4.3,0.6,6.3-0.2c0.4-0.2,0.9-0.4,1.1-0.8c0.2-0.3,0.2-0.8,0.2-1.2c0-2.2-1.2-4.2-2.8-5.8c-1.6-1.5-3.5-2.6-5.5-3.5
              c-3.7-1.8-7.7-3.2-11.8-3.4c-4.1-0.2-8.4,0.9-11.5,3.7c-2,1.8-4.3,5-5.4,7.4C221.3,319.9,225.8,320.8,228.3,319.6z"/>
            <path className="__umbrella-totoro-6" d="M217.4,284.8c6.4,0.5,12,5.6,18.4,5.1c0.6,0,1.3-0.2,1.4-0.8c0.1-0.4-0.1-0.7-0.2-1.1
              c-3.4-6.7-10.7-10.8-18-12.5c-7-1.6-13.9-0.9-19.7,3.6c-4,3-6.1,6.8-4.1,11.9C202.9,291.6,209.4,284.2,217.4,284.8z"/>
            <path className="__umbrella-totoro-6" d="M167.9,323.8c0,0.3-0.1,0.5,0.1,0.8c0.2,0.4,0.6,0.5,1.1,0.5c1.8,0.1,3.4-1.1,4.6-2.5
              c1.2-1.3,2.2-2.9,3.7-3.9c3.1-2,7.1-1,10.4,0.5c1.1,0.5,2.1,1,3.3,1c1.7,0.1,3.4-0.9,5.1-0.4c1.2,0.4,2.1,1.4,3,2.3
              s2.2,1.7,3.4,1.4c1.9-4.4-0.6-9.9-4.8-12.3c-4.1-2.5-9.3-2.5-14-1.4c-3.6,0.8-7.2,2.3-10,4.8C171.4,316.7,168.3,320.6,167.9,323.8
              z"/>
            <path className="__umbrella-totoro-6" d="M128.6,305.8c2.6,1,5.6-0.2,7.8-1.9s4.1-3.9,6.4-5.4c3.5-2.3,7.9-2.8,12.1-2.9c4.2-0.1,8.5,0.1,12.5-1
              c0.9-0.2,1.9-0.7,2-1.6c0-0.4-0.1-0.8-0.4-1.2c-1.4-2.4-4.2-3.7-6.9-4.5c-2.3-0.7-4.6-1.2-7-1.7c-2.7-0.6-5.4-1.2-8.1-1
              C136.9,285,130.5,297.1,128.6,305.8z"/>
            <path className="__umbrella-totoro-6" d="M123.8,324.7c-2.8,4.2-5.2,8.7-6.9,13.4c3.9,0.3,7.9-0.9,10.9-3.5c2.1-1.8,3.9-4.2,6.7-4.8
              c1.5-0.3,3,0,4.5,0.2c3.3,0.4,6.7,0,9.8-1c0.6-0.2,1.4-0.6,1.5-1.2c0.1-0.7-0.5-1.2-1-1.6c-4.2-3.1-9.2-5.3-14.4-6.3
              C129.8,318.9,126.7,320.4,123.8,324.7z"/>
          </g>
          <g>
            <path className="__umbrella-totoro-7" d="M337.3,263.3c-25.3-10.7-61.8-22.6-89.2-24.9c-1.9,15.5,4.3,43.4,10.6,57.7c5.1,11.6,10.6,23,18.2,33.1
              c18.3,24.3,48.8,38.9,79.2,37.9"/>
            <path d="M337.2,263.4c-28.6-11.4-58.4-21.2-89.2-24.2l0.9-0.7c-0.8,7.7,0.1,15.5,1.3,23.2c2,11.5,4.8,22.9,9.4,33.7
              c4.8,10.6,9.9,21.2,16.7,30.7c13.5,18.9,34.1,32.5,56.6,38c7.5,1.9,15.3,2.9,23.2,2.9l0,0.3c-7.8,0.5-15.8,0-23.5-1.6
              c-32.8-6.7-56.9-28.3-70.2-58.6c-7-14.2-11.7-29.4-14-45c-1.2-8.1-2-16.3-1-24.4l0.8,0.1c15.5,1.5,30.6,5.2,45.5,9.6
              c14.9,4.5,29.4,9.8,43.7,15.9L337.2,263.4L337.2,263.4z"/>
          </g>
          <rect x="241.9" y="93.1" className="__umbrella-totoro-8" width="4" height="131"/>
          <path className="__umbrella-totoro-8" d="M246.2,265.8c-0.5,3.4-1.8,6.3-5.3,8.9c-4.7,3.6-12.1,3-16.2-1.3c-4.4-4.5-4.3-13-4.3-18.9
            c0-1.2,0.1-2.4,0.6-3.4s1.6-1.9,2.8-1.7c1,0.1,1.7,0.8,2,1.8s0.2,2.1,0.2,3.1c-0.1,3.2-0.2,7.8,1.2,10.8c0.9,1.8,3.1,3.6,5.2,3.5
            c4.1-0.3,4.5-5.3,4.7-8.4l1.2-36.9h10c-0.5,12.5-1,25-1.6,37.6C246.5,262.6,246.4,264.3,246.2,265.8z"/>
          <g>
            <path className="__umbrella-totoro-9" d="M159.6,530.2c4.2,0.2,8.4,0.3,12.6,0.5c1.8,0.1,7.2,1.4,5.9-1.7C175.7,523.2,163.1,530.4,159.6,530.2z"/>
            <path className="__umbrella-totoro-10" d="M149.9,526.7c-2.1,0.6-4.2,1.4-6.2,2.3c4.5,0.3,9,0.5,13.5,0.3c1.5,0,3.8,0,5-1c2.1-1.5-0.2-2.1-2-2.4
              c-3.8-0.8-7.6,0.1-11.4,0.2c-4.8,0.2-9.3,1.1-14.1,0.4c2-1.5,4.5-2.4,7.1-2.5s5.1,0.3,7.5,1.2"/>
          </g>
          <g>
            <path className="__umbrella-totoro-9" d="M279.8,535.5c-4.2,0.2-8.4,0.3-12.6,0.5c-1.8,0.1-7.2,1.4-5.9-1.7C263.8,528.5,276.4,535.7,279.8,535.5z"/>
            <path className="__umbrella-totoro-10" d="M289.5,532c2.1,0.6,4.2,1.4,6.2,2.3c-4.5,0.3-9,0.5-13.5,0.3c-1.5,0-3.8,0-5-1c-2.1-1.5,0.2-2.1,2-2.4
              c3.8-0.8,7.6,0.1,11.4,0.2c4.8,0.2,9.3,1.1,14.1,0.4c-2-1.5-4.5-2.4-7.1-2.5s-5.1,0.3-7.5,1.2"/>
          </g>
          <path className="__umbrella-totoro-11" d="M369,332.9c0.7-20.7-19.8-53-20.2-57.3s-12.2-10.3-12.2-10.3s0,0,0.6-3.9c0.5-3.9-6.5-12.3-12.4-20.7
            s-12.4-10.3-12.4-10.3s-1.7-8.6-2.8-11.2c-1.1-2.6,0.4-8.4-5.4-13.6c-5.7-5.1-10-13.6-10-13.6c-9.8-22.9-18.3-25.9-18.3-25.9v-3.5
            l3.1-0.4c0,0,3.9-1.8,3.9-3.7s-5.7-7.5-5.7-7.5s6.8,5.9,13.3-11.8s-2.8-43.9-2.8-43.9c-2.4-10.1-8.9-4-8.9-4
            c-35.7,35.2-17.7,56.2-17.7,56.2s0.9,1.8-3,1.8s-3.9,5.7-3.9,5.7s-9.8,3.1-13.1-0.6s-10.5-5.3-14.1-5.9c-3.5-0.6-13.5,0.2-16.1,3.1
            s-8.1,2-13.5,2s-12.6,5.5-10.7,1.6c1.9-3.9-1.5-4.9-1.5-4.9s-2.2-0.7,0-1.6s2.4-3.3,1.7-4.9c-0.7-1.6,0.9-4,0.9-4
            c2.8-5.9-2-18.5-4.1-20.9c-2-2.4-1.2-9-3.1-15.4c-1.9-6.4-6.3-13.8-13.4-13.6c-10.2,0.3-14.8,23.5-17.2,37.8
            c-2.4,14.3,5.4,24.8,5.4,24.8l-3.3,4.8l9.8,0.9c0,0,0,2.4,0,3.5s-7.2,2.2-8.9,4s8.9,3.1,8.9,3.1l-17,11.9c0,0-7.9,0-8.5,5.9
            s-3.7,10.3-3.7,10.3c-15.9,14.3-15.5,37.4-15.9,39.3c-0.4,1.8-11.8,13.9-19.6,16.9c0,0,27.5,1.6,60,12.5
            c47.7,16,106.3,52.2,105.2,132.9c-0.1,7.6-0.7,14.6-1.8,21C243,528.2,98.6,477.4,98.6,477.4c3.3,8.4,8,16.2,15.8,20.5
            c4.7,2.6,10.4,3.6,14.4,7.2c3.6,3.2,5.4,7.9,8.3,11.7c9.6,12.7,28.2,13.5,44.2,13.1c5.7-0.2,25.3,2.5,30.8,0.7s2-2,3.8-7.5
            c1.7,6.1,1.9,8.9,8.2,9.8s55.5,2,58.8,0.8c33-12.4,35.5-38.3,56.6-66.4c6.1-8.1,11.8-16.6,15.5-26c10.4-25.9,3.5-60.7,13.1-87
            c-0.5-2.9-0.9-10.5-0.9-10.5C370.1,339.5,369,332.9,369,332.9z"/>
          <path className="__umbrella-totoro-10" d="M249.3,238.6c-4.9-0.4-9.9-0.3-14.8,0.3c-0.4,0.4-0.2,1.1,0.2,1.4c0.4,0.4,0.9,0.5,1.4,0.6
            c5.7,1.5,11.4,2.9,17.2,4.3c1.6,0.4,3,0.4,3.8,2.1c1.3,2.6-0.8,3.9-3.2,3.5c-5.1-1-10-3-14.8-5c-1.6-0.7-3.4-1.5-4-3.2
            c5.5,0.6,14.4,4.2,19.6,1.6C261.2,240.9,252.5,238.9,249.3,238.6z"/>
          <path className="__umbrella-totoro-10" d="M238.3,250.2c1,1.2,2.5,1.7,4,2.1c3.1,0.9,6.2,1.7,9.3,2.2c1.3,0.2,4-0.2,3.7-2.1c-0.2-1.6-3.1-1.5-4.3-1.7
            C246.8,250.2,242.5,250,238.3,250.2z"/>
          <path className="__umbrella-totoro-10" d="M252.4,255.4c-3.9-0.4-7.9-0.6-11.8-0.4c2.4,1.4,4.9,2.8,7.6,3.5c0.9,0.2,1.8,0.3,2.6,0
            c0.8-0.3,1.5-1.2,1.3-2.1c-0.2-0.7-0.9-1.2-1.6-1.1"/>
          <g>
            <path className="__umbrella-totoro-12" d="M231.2,71c-23.3,0.7-42.4,4.1-58.1,9c-53.2,16.5-66.6,49.4-66.6,49.4s16.3-10.5,40-9.2c0,0,53.1-20.3,98.9,0
              c0,0,34.7-15,72.1,0.2c0,0,10.8-2.5,24.9,3.7c2,0.9,4.1,1.9,6.2,3.2c6.3,3.4,10.2,5.8,11.3,6.5C364.4,129.8,321.2,68.2,231.2,71z"
              />
            <path d="M231.2,71c-41.3,1.3-86.1,11.7-114.6,43.6c-3.9,4.5-7.4,9.4-10,14.8l-0.3-0.3c12.1-7,26.3-9.9,40.1-9.3
              c15.3-5.4,32.8-8.6,49.5-9c8.4-0.2,16.9,0.3,25.2,1.8c7.8,1.3,17.8,4.1,24,7.2c5.8-2.3,11.8-3.8,17.8-4.9
              c18.2-3.3,37.3-1.8,54.6,5.2c6.2-1.4,15.6,0.1,22.4,2.7c3.5,1.3,7,3.2,10.3,5.1c3.3,1.8,6.6,3.7,9.9,5.7l-0.5,0
              c0.1-0.1,0.2-0.4,0.2-0.7c0-0.3-0.1-0.7-0.2-1.1c-0.7-2.2-1.9-4.2-3.2-6.2C351,117.7,344.2,111,337,105
              C307.6,80.8,269,69.8,231.2,71L231.2,71z M231.2,70.9c37.8-1.4,76.7,9.4,106.2,33.5c7.4,6.1,21.2,18.9,23.1,28.3
              c0,0.4,0,1-0.5,1.5l-0.2,0.2l-0.3-0.2c-5.6-3.4-11.4-6.8-17.3-9.7c-4.3-1.9-8.9-3.2-13.5-3.8c-3.9-0.6-7.6-0.4-11.4,0.1
              c-11.4-4.5-23.6-6.9-35.8-6.8c-12.3-0.1-24.8,2.4-36.2,6.5c-15.4-6.8-32.4-9.4-49.2-9c-16.8,0.4-33.7,3.4-49.6,8.9
              c-10.4-0.5-20.8,1-30.5,4.7c-3.5,1.3-6.9,2.9-10,4.8c2.6-5.8,6.3-10.9,10.4-15.6C145.1,82.5,189.9,72.2,231.2,70.9L231.2,70.9z"/>
          </g>
          <path className="__umbrella-totoro-13" d="M168.3,83.1c0,0-15.6,7-19.7,28.1"/>
          <g>
            <path d="M237.5,72.8c4.9,5.5,7.2,12.9,8.2,20.1c1.1,7.3,0.6,14.7-1.1,21.9c-0.2-0.9,0.4-4.2,0.4-5.5c0.3-4.4,0.4-9.1,0.2-13.6
              C244.7,87.6,242.4,79.1,237.5,72.8L237.5,72.8z"/>
          </g>
          <g>
            <path d="M298.8,84.9c9.9,7.5,19.3,18.8,18.4,31.9h-0.1C316.3,103.9,308.2,92.8,298.8,84.9L298.8,84.9z"/>
          </g>
          <path className="__umbrella-totoro-14" d="M163.1,189.4c0,0,8.4,11.4,31.7,0"/>
          <path className="__umbrella-totoro-14" d="M284,189.4c0,0-8.4,11.4-31.7,0"/>
        </g>
    </svg>
  );
}
