import React, { FunctionComponent } from 'react'


const Watch: FunctionComponent = ({
  height = 80,
  width = 80,
  radius = 48,
  color = "var(--green)",
  ariaLabel = 'watch-loading',
  wrapperStyle,
  wrapperClass,
  visible = true,
}) => (
  <div
    className={wrapperClass}
    data-testid="watch-loading"
    aria-label={ariaLabel}
  >
    <svg
      width={width}
      height={height}
      version="1.1"
      id="L2"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 110 110"
      enableBackground="new 0 0 110 110"
      xmlSpace="preserve"
      data-testid="watch-svg"
    >
      <circle
        fill="none"
        stroke={color}
        strokeWidth="10"
        strokeMiterlimit="10"
        cx="55"
        cy="55"
        r={radius}
      />
      <line
        fill="none"
        strokeLinecap="round"
        stroke={color}
        strokeWidth="10"
        strokeMiterlimit="10"
        x1="55"
        y1="55"
        x2="85"
        y2="50.5"
      >
        <animateTransform
          attributeName="transform"
          dur="2s"
          type="rotate"
          from="0 55 55"
          to="360 55 55"
          repeatCount="indefinite"
        />
      </line>
      <line
        fill="none"
        strokeLinecap="round"
        stroke={color}
        strokeWidth="10"
        strokeMiterlimit="10"
        x1="55"
        y1="55"
        x2="54.5"
        y2="79"
      >
        <animateTransform
          attributeName="transform"
          dur="15s"
          type="rotate"
          from="0 55 55"
          to="360 55 55"
          repeatCount="indefinite"
        />
      </line>
    </svg>
  </div>
)

export default Watch