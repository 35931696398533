export default function sittingTotoro(props) {
  return (
    <svg
      viewBox="0 0 1423.42 808.93"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <style>
        {".__sitting-totoro-5{fill:#3C3C30;}.__sitting-totoro-17{fill:#FFFFFF;}.__sitting-totoro-18{fill:#633D0E;}.__sitting-totoro-19{fill:#676654;stroke:#000000;stroke-width:3.1348;stroke-miterlimit:10;}.__sitting-totoro-20{fill:none;stroke:#000000;stroke-width:3.1348;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}.__sitting-totoro-21{fill:#676654;}.__sitting-totoro-22{fill:#FFFFFF;stroke:#000000;stroke-width:3.1348;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}.__sitting-totoro-23{fill:#FEFDEB;stroke:#000000;stroke-width:3.1348;stroke-miterlimit:10;}.__sitting-totoro-24{stroke:#000000;stroke-width:3.1348;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}.__sitting-totoro-25{stroke:#000000;stroke-width:3.1348;stroke-miterlimit:10;}.__sitting-totoro-26{fill:#633D0E;stroke:#000000;stroke-width:6.2697;stroke-miterlimit:10;}.__sitting-totoro-27{fill:#E5DEC9;}.__sitting-totoro-28{opacity:0.8;fill:#3C3C30;}"}
      </style>
        <g>
          <path className="__sitting-totoro-18" d="M1390.99,683.58c-5.93,0.13-11.86,0.03-17.79-0.08c-6.25-0.11-12.52-0.22-18.69-1.16
            c-5.93-0.9-11.72-2.57-17.46-4.3c-21.53-6.49-39.73-21.08-62.19-22.07c-7.82-0.35-15.29-3.17-22.91-4.94
            c-9.55-2.22-19.39-2.78-29.04-4.51c-2.98-0.53-6.14-1.29-8.19-3.51c-0.75-0.82-1.33-1.81-2.21-2.49c-0.87-0.66-1.96-0.96-3.02-1.24
            c-3.83-1.01-6.52-0.31-9.76-2.58c-2.98-2.09-6.17-4.25-9.8-4.42c-1.7-0.08-3.38,0.28-5.04,0.64c-2.44,0.53-4.88,1.06-7.32,1.6
            c-1.45,0.32-2.92,0.64-4.41,0.62c-2.73-0.04-5.3-1.19-7.82-2.25c-4-1.69-8.08-3.18-12.22-4.48c-2.13-0.67-4.28-1.25-6.43-1.84
            c-1.37-0.38-2.66-1.09-4.06-1.26c-4.3-0.5-9.13,0.29-13.46,0.43c-4.38,0.15-8.77,0.29-13.15,0.43
            c-24.07,0.78-48.15,1.48-72.22,2.06c-23.83,0.57-47.65,1.02-71.48,1.31c-23.89,0.29-47.78,0.42-71.67,0.35
            c-12.07-0.03-24.13-0.12-36.2-0.25c-6.08-0.07-12.16-0.15-18.24-0.24c-3.06-0.05-6.11-0.1-9.16-0.15
            c-1.59-0.03-3.18-0.06-4.78-0.09c-0.83-0.02-3.38-0.51-4.05-0.08c-4.49,2.9-10.07,3.61-15.42,3.41c-5.34-0.2-10.62-1.25-15.96-1.55
            c-4.61-0.26-9.22,0.04-13.83,0.33c-14.8,0.95-29.85,1.95-43.73,7.18c-4.02,1.52-7.93,3.34-12.1,4.43
            c-3.24,0.85-5.22,3.04-8.34,4.29c-2.64,1.05-5.48,1.46-8.29,1.93c-15.02,2.51-29.67,6.97-44.81,8.81
            c-6.5,0.79-14.1,1.84-20.39-0.15c-6.46-2.05-13-4.45-19.58-6.14c-6.83-1.76-13.94-2-20.99-2.16c-15.99-0.36-30.79,3.11-46.48,0
            c-7.38-1.46-14.77-2.93-22.26-3.62c-13.92-1.29-27.94,0.08-41.86,1.35c-31.59,2.88-61.14,9.01-92.74,6.19
            c-9.06-0.81-17.86,2.69-26.72,4.76c-6.54,1.53-13.22,2.29-19.89,3.05c-5.49,0.62-10.42,0.35-15.76,1.77
            c-9.43,2.5-18.99,4.5-28.63,5.97c-9.18,1.41-18.14,1.52-27.43,1.29c-4.94-0.12-9.89-0.25-14.83-0.37
            c-2.67-0.07-5.38-0.13-7.99,0.44c-8.13,1.77-13.99,10.61-22.26,9.7c-3.02-0.33-6.04-0.66-9.07-1c-1.5-0.16-3.01-0.33-4.52-0.17
            c-1.59,0.17-3.13,0.7-4.62,1.28c-9.53,3.71-18.18,9.58-27.99,12.48c-9.43,2.79-18.37,0.97-27.5,4.63
            c-19.43,7.78-37.67,18.72-57.74,24.63c-2.94,0.87-5.88,1.73-8.82,2.6c-1.13,0.33-2.27,0.67-3.25,1.32
            c-1.54,1.02-2.5,2.69-3.52,4.24c-2.44,3.7-5.07,6.07-8.27,9.12c-4.39,4.17-9.02,8.24-14.55,10.71c-6.07,2.73-13.53,3.42-17.87,8.47
            c-12.43,14.48-22.32,31.12-29.1,48.95l90.35,0.2c-0.17-5.6,4.36-10.39,9.45-12.71c3.08-1.41,6.45-2.16,9.84-2.2
            c2.28-0.03,4.57,0.26,6.84,0.11c9.84-0.68,15.35-7.73,22.54-13.32c10.54-8.19,23.1-13.47,33.58-21.74
            c2.73-2.16,5.39-4.61,6.77-7.81c1.79-4.13,0.97-10.42,3.67-14.03c4.39-0.76,6.74-5.43,9.94-8.55c3.59-3.5,8.58-5.17,13.48-6.23
            c19.23-4.17,39.16-0.72,58.76,1c27.31,2.4,53.76,0.45,81.06-1.97c7.23,2.35,14.47,4.7,21.7,7.06c6.2,2.02,12.5,4.05,19.02,4.34
            c5.26,0.23,10.49-0.69,15.74-1.01c5.06-0.31,10.14-0.07,15.2,0.17c16.32,0.78,32.12,1.21,48.44,1.99
            c6.24-1.89,12.47,2.51,18.84,3.89c2.97,0.64,6.03,0.63,9.06,0.6c15.17-0.13,30.34-0.38,45.51-0.76c5.76-0.14,12.02-0.49,17.61-1.89
            c1.47-3.66,5.07-6.07,8.81-7.31c3.74-1.24,7.72-1.52,11.6-2.23c4.82-0.89,9.49-2.47,14.12-4.07c19.26-6.67,38.33-14.01,58.06-19.12
            c19.73-5.12,41-9.26,61.25-6.9c41.7,4.85,83.96,6.63,125.94,7.41c3.46-2.75,7.27-5.14,11.48-6.49c3.44-1.11,7.06-1.49,10.66-1.88
            c5.01-0.53,10.02-1.07,15.02-1.6c4.04-0.43,8.08-0.86,12.13-1.06c4.91-0.24,9.83-0.15,14.73-0.46c5.29-0.34,10.24-0.87,15.17-2.07
            c2.05-0.5,4.1-1.09,6.17-1.87c22.51-8.53,47.45-5.63,71.5-4.5c19.59,0.92,40.44,4.03,59.3,5.37c7.29,0.52,14.32,0.79,20.82,0.53
            c6.75-0.27,10.31,8.97,16.58,11.47c6.91,2.76,14.54,2.85,21.94,3.67c26.14,2.92,50.12,19.27,76.37,20.89
            c15.76,0.97,31.57,0.29,47.35,0.72c15.78,0.44,32.65,2.14,47.54,7.39c11.79,4.15,23.62,8.32,35.83,10.97
            c6.73,1.46,13.58,2.46,20.19,4.42c5.01,1.49,9.85,3.51,14.86,4.99c15.33,4.52,31.83,4.71,47.79,3.9v-81.96
            C1414.77,681.91,1402.51,683.33,1390.99,683.58z"/>
          <path className="__sitting-totoro-19" d="M1175.79,601.21c1.24-1.63,3.27-2.71,3.96-4.65c0.4-1.14,0.26-2.4,0.1-3.6c-0.59-4.68-1.18-9.37-1.77-14.05
            c-0.07-0.52-0.13-1.05-0.2-1.57c-0.4-3.17-0.84-6.47-2.59-9.15c-1.44-2.21-3.83-4.56-2.83-7.01c0.51-1.24,1.81-2.06,2.14-3.36
            c0.54-2.13-1.74-3.79-3.62-4.91c-4.82-2.85-9.2-6.47-12.91-10.67c-1.78-2.01-3.73-4.33-6.41-4.52c-1.6-0.11-3.14,0.59-4.72,0.88
            c-5.11,0.93-10.74-2.45-15.36-0.06c-1.11,0.57-2.06,1.45-3.24,1.87c-3.19,1.12-6.91-1.47-9.99-0.07c-1.67,0.76-2.65,2.5-4.05,3.68
            c-1.92,1.62-4.52,2.12-6.87,3.01c-9.91,3.75-15.25,14.16-21.79,22.5c-5.28,6.74-5.82,9.15-13.17,13.53
            c-1.03,5.16-6.14,14.03-6.38,19.29c-0.59,13.25,0.09,28.32-7.25,38.86c0.13,0.26,0.24,0.54,0.38,0.8c-0.13-0.26-0.25-0.54-0.38-0.8
            c-0.39,0.55-0.85,1.06-1.29,1.59c-1.18,3.14-1.94,6.37-1.71,9.7c0.24,3.37,1.47,6.59,2.75,9.72c2.59,6.36,5.57,12.85,10.78,17.33
            c6.73,5.78,16.03,7.26,24.81,8.52c6.4,0.92,13.06,1.82,19.22-0.15c6.78-2.16,12-7.49,16.83-12.71
            c11.52-12.44,22.5-25.37,32.93-38.74c1.96-2.51,3.92-5.07,5.24-7.97c1.09-2.38,1.74-4.94,2.35-7.48c0.96-4.03,2.43-7.41,4.05-10.57
            C1174.12,607.21,1173.84,603.78,1175.79,601.21z"/>
          <path className="__sitting-totoro-20" d="M956.07,651.27c0.02-0.19,0.03-0.38,0.05-0.56c-0.32,0.17-0.66,0.34-0.97,0.51
            C955.35,651.5,955.83,651.53,956.07,651.27z"/>
          <g>
            <path className="__sitting-totoro-21" d="M1246.45,424.53c4.72-7.08,2.95-17.7,2.95-17.7c1.18-33.34-31.57-85.27-32.16-92.05
              c-0.59-6.79-19.47-16.52-19.47-16.52s0,0,0.89-6.2c0.88-6.2-10.33-19.77-19.77-33.34c-9.44-13.57-19.77-16.52-19.77-16.52
              s-2.66-13.87-4.43-18c-1.77-4.13,0.59-13.57-8.56-21.83c-9.15-8.26-15.93-21.83-15.93-21.83c-15.64-36.88-29.21-41.6-29.21-41.6
              v-5.61l5.02-0.59c0,0,6.2-2.95,6.2-5.9c0-2.95-9.15-12.1-9.15-12.1s10.92,9.44,21.24-18.88c10.33-28.32-4.43-70.52-4.43-70.52
              c-3.83-16.23-14.2-6.48-14.2-6.48c-56.9,56.64-28.28,90.28-28.28,90.28s1.48,2.95-4.72,2.95s-6.2,9.15-6.2,9.15
              s-15.64,5.02-20.95-0.89c-5.31-5.9-16.82-8.56-22.42-9.44c-5.61-0.89-21.54,0.29-25.67,5.02c-4.13,4.72-12.98,3.25-21.54,3.25
              c-8.55,0-20.06,8.85-17.11,2.65c2.95-6.2-2.36-7.97-2.36-7.97s-3.54-1.18,0-2.66c3.54-1.47,3.84-5.31,2.65-7.97
              c-1.18-2.66,1.48-6.49,1.48-6.49c4.43-9.44-3.25-29.8-6.49-33.63c-3.25-3.84-1.99-14.48-4.94-24.81
              c-2.95-10.33-10.11-22.1-21.32-21.81c-16.23,0.43-23.6,37.77-27.44,60.78c-3.84,23.01,8.55,39.83,8.55,39.83l-5.31,7.67
              l15.64,1.47c0,0,0,3.84,0,5.61c0,1.77-11.51,3.54-14.16,6.49c-2.66,2.95,14.16,5.02,14.16,5.02l-27.14,19.18
              c0,0-12.69,0-13.57,9.44c-0.88,9.44-5.9,16.52-5.9,16.52c-25.37,23.01-24.78,60.19-25.37,63.14
              c-0.59,2.95-18.88,22.42-31.27,27.14c-12.39,4.72-33.05,54.88-33.05,54.88c-19.47,11.21-23.6,85.56-27.34,88.51
              c-3.74,2.95,1.97,61.96,0.2,63.73s8.85,17.7,7.67,27.14c-1.18,9.44,10.62,21.83,10.62,21.83l21.48,10.74l4.48,2.24l3.25-28.91
              c0,0-6.49,0-7.08-2.65s6.49-12.39,7.38-15.93c0.88-3.54-4.13-39.54,2.95-63.43c7.08-23.9,22.72-52.22,22.72-52.22l-5.9,0.59
              c0-13.28,20.65-26.55,20.65-26.55l-9.44,2.07c5.31-10.33,23.9-19.77,23.9-19.77l-6.79-1.47c14.75-25.67,75.24-46.62,75.24-46.62
              l-1.18-5.9l21.83-0.3l-1.77-5.9l19.77,2.54c0,0,1.48-5.49,25.08-7.85c23.6-2.36,24.78,9.15,24.78,9.15
              c6.79-6.79,42.38-4.97,42.38-4.97l-8.16,4.97c14.46-2.95,38.65,10.62,38.65,10.62s25.08,2.95,28.03,7.67
              c2.95,4.72,18.59,14.16,25.08,14.46c6.49,0.3,15.05,21.83,15.05,21.83s7.38,0.59,11.51,5.9c4.13,5.31,6.2,12.1,6.2,14.16
              s24.78,33.93,24.78,33.93l1.77,24.49c5.9,3.25,5.02,31.27,5.02,31.27s-4.72-3.54-4.72,0.29c0,3.84,4.72,15.64,4.72,15.64
              l0.29,15.34l-6.66,66.28l-0.43,4.24c48.39-31.57,49.86-104.74,48.98-109.46C1247.04,436.62,1246.45,424.53,1246.45,424.53z"/>
            <g>
              <path d="M1246.05,417.16c0.72-3.26,0.7-6.87,0.24-10.16c0.17-4.96-0.39-10.02-1.35-15.05c-5.13-25.3-17.8-48.09-28.76-71.29
                c-0.76-1.76-1.67-3.78-1.97-5.92c-0.86-2.26-3.02-3.99-5.18-5.81c-4.36-3.46-9.48-6.43-14.47-9.05c0.18-1.23,0.75-5.16,0.93-6.48
                c0.14-1.18,0.44-2.07,0.1-3.32c-2.67-7.84-8.66-14.81-13.45-21.8c-3.46-4.63-6.58-9.78-10.53-13.99
                c-4.16-4.34-9.12-8.24-14.96-9.87c0,0-0.33-1.68-0.33-1.68c-0.96-4.89-1.99-9.86-3.28-14.56c-0.28-1.17-0.76-2.32-1.15-3.43
                c-0.57-1.72-0.66-3.07-0.81-4.37c-0.36-6.21-2.15-11.85-6.85-15.96c0,0-0.73-0.7-0.73-0.7c-0.48-0.47-0.99-0.92-1.43-1.41
                c-5.75-6.01-10.19-12.78-14.06-20.02c-0.8-1.61-2.02-4.51-2.77-6.18c-2.03-4.46-4.35-9.05-6.8-13.29
                c-3.06-5.38-6.51-10.42-10.59-14.98c-2.27-2.41-4.79-4.88-7.71-6.35c0,0-1.83-2.57-1.83-2.57l0.01-5.61l0-2.42l2.39-0.28
                l5.02-0.58l-0.85,0.24c1.49-0.74,3.2-1.82,4.14-2.83c0.49-0.48,0.6-0.97,0.51-0.59c-1.7-3.4-5.42-7.21-8.32-10.25
                c0,0,3.64-3.92,3.64-3.92c-0.04-0.03,0.03,0.02,0.09,0.06c1.09,0.72,2.5,0.97,3.77,0.65c4.34-1.1,7.28-6.1,9.4-9.84
                c0,0,1.35-2.82,1.35-2.82c0.47-0.92,0.85-1.91,1.24-2.89c6.23-14.72,5.3-31.23,2.87-46.78c-1.41-8.47-3.44-16.98-6.11-25.06
                c-0.85-3.05-1.59-6.24-4.38-7.42c-2.13-0.49-4.65,1.02-6.13,2.58c-9.27,9.36-17.98,19.6-24.44,31.04
                c-9.67,16.62-14.89,39.17-2.95,55.83c0.91,1.76,0.19,4.11-1.34,5.09c-2.05,1.29-3.57,1.23-5.62,1.34
                c-3.52,0.16-3.76,5.79-3.77,8.52c-7.63,2.24-19.56,5.23-25.61-1.69c-5.8-5.16-14.11-7.14-21.68-8.27
                c-5.04-0.33-10.28,0.16-15.18,1.23c-2.37,0.53-5.46,1.48-7.07,3.06c-1.25,1.33-2.9,2.51-4.63,3.1
                c-6.21,2.09-12.69,0.87-18.93,0.99c-4.29,0.19-8.15,2.02-12.31,3.65c-1.39,0.52-2.84,1.18-4.49,1.17
                c-1.53,0.03-2.95-1.48-2.81-2.98c0-0.31,0.04-0.48,0.07-0.65c0.07-0.4,0.16-0.64,0.25-0.91c0.19-0.5,0.41-0.95,0.49-1.17
                c0.22-0.54,0.43-1.07,0.49-1.62c0.26-1.52-0.54-2.5-1.79-3.01c-1.24-0.47-2.7-1.37-2.93-2.96c-0.16-1.07,0.29-2.01,1.05-2.69
                c0.54-0.5,1.23-0.83,1.87-1.12c1.44-0.61,2.05-1.84,1.88-3.52c-0.06-0.54-0.19-1.01-0.43-1.58c-0.51-1.2-0.52-2.4-0.38-3.41
                c0.33-1.99,1.02-3.48,2.09-5.15c0,0-0.18,0.32-0.18,0.32c2.7-7.37,0.08-16.13-2.34-23.37c-0.7-1.94-1.49-3.87-2.41-5.64
                c-1.09-2.16-2.74-3.81-3.23-5.96c-2.01-8.42-1.33-17.05-4.52-25.08c-2.94-7.93-8.53-16.98-17.82-17.17
                c-16.74-0.24-24.32,48.72-26.31,62.05c-1.37,12.68,1.99,26.04,9.34,36.46c0,0-0.77,1.1-0.77,1.1l-5.32,7.67l-1.4-3.02l15.64,1.49
                l1.73,0.17l0,1.73l-0.01,4.7l0,0.59l0,0.29c0-0.02-0.01,0.18-0.02,0.29c-0.02,0.82-0.86,1.69-1.29,1.96
                c-3.71,2.1-8.07,2.63-11.77,4.49c-0.65,0.34-1.26,0.68-1.57,1.03c0.24-0.32,0.25-0.87,0.04-1.18c0.83,0.59,2.61,1.1,3.93,1.45
                c4.22,1.04,9.36,1.73,13.74,2.28c-2.15,1.55-28.61,20.14-31.49,22.19c-2.81,0.04-5.93,0.61-8.35,1.95
                c-2.23,1.22-3.72,3.15-4.01,5.69c-0.32,3.04-1.01,6.2-2.08,9.12c-1.16,3.09-2.45,6.04-4.45,8.73
                c-5.29,4.82-9.73,10.54-13.23,16.82c-7.05,12.65-10.36,27.11-11.36,41.52c0,0-0.2,2.77-0.2,2.77c-0.03,0.26-0.03,0.46-0.09,0.81
                c0,0.22-0.24,0.94-0.35,1.11c-1.22,2.25-2.87,4.02-4.47,5.86c-5.04,5.55-10.5,10.61-16.49,15.11c-3.39,2.5-7.02,4.91-11.07,6.5
                c0,0-0.99,0.49-0.99,0.49c-2.14,1.37-3.92,3.26-5.54,5.28c-10.95,14.45-18.4,31.63-25.66,48.17c0,0-0.2,0.49-0.2,0.49l-0.46,0.27
                c-3.54,2.14-5.88,5.76-7.81,9.38c-5.88,11.68-8.59,24.71-11.19,37.51c-2.11,10.8-3.63,21.74-5.52,32.61
                c-0.41,2.2-0.78,4.36-1.49,6.66c-0.11,0.3-0.19,0.58-0.36,0.92c-0.14,0.3-0.33,0.73-0.85,1.2c0.04-0.02,0.07-0.05,0.09-0.08
                c0.01-0.02,0,0.02,0,0.02c-0.01-0.01-0.05,0.11-0.08,0.16c-0.06,0.16-0.12,0.37-0.18,0.6c-0.57,3.04-0.58,6.4-0.6,9.56
                c-0.09,12.78,1.07,26.77,1.54,39.62c0.07,4.28,0.62,8.58-0.04,12.86c-0.06,0.16-0.03,0.19-0.23,0.52
                c-0.04,0.07-0.09,0.13-0.14,0.19c-0.05,0.06-0.12,0.14-0.07,0.09c0.04-0.03,0.07-0.06,0.09-0.1c0.1-0.23,0.09-0.16,0.09-0.23
                c-0.02,0.13,0.07,0.66,0.22,1.11c0.47,1.5,1.12,2.99,1.75,4.47c2.76,6.74,6.45,13.64,5.85,21.19c0,0-0.06,1.46-0.06,1.46
                l0.15,1.49c0.05,0.5,0.21,1,0.31,1.49c1.67,6.12,5.67,11.55,9.85,16.26c0,0-0.36-0.27-0.36-0.27l25.95,13.01l-1.82,0.99
                l3.28-28.91l1.22,1.36c-2.79-0.13-6.07-0.26-7.93-2.76c-0.46-0.73-0.45-1.73-0.25-2.54c0.41-1.66,1.17-3.03,1.92-4.49
                c1.69-3.3,3.93-6.49,5.28-9.92c0.04-0.15,0.11-0.3,0.13-0.44l0.03-0.43c-0.41-15.56-1.86-31.24-0.03-46.86
                c1.11-12.3,5.33-24.07,10.03-35.4c4.72-11.32,10.05-22.33,15.88-33.12l1.02,1.54l-5.9,0.58l-1.15,0.11
                c-0.12-3.51,0.86-6.96,2.52-9.9c4.43-7.78,11.37-13.62,18.64-18.63c0,0,0.75,1.81,0.75,1.81l-9.44,2.05l-2.07,0.45
                c2.63-5.6,7.11-9.89,11.93-13.54c4.23-3.21,8.7-5.99,13.4-8.48c0,0,0.23,1.76,0.23,1.76l-6.78-1.48l-1.25-0.27l0.64-1.11
                c10.84-17.62,36.43-30.08,54.87-38.54c6.87-3.04,13.81-5.87,20.91-8.39l-0.54,0.94c-0.11-0.66-1.28-6.38-1.36-6.87
                c0,0,0.98-0.01,0.98-0.01l21.83-0.27l-0.75,1.02l-1.76-5.9l-0.35-1.16l1.19,0.15l19.77,2.56l-0.83,0.55
                c0.36-1.01,1.24-1.68,2.04-2.3c3.23-2.17,7.06-3.22,10.77-4.08c10.66-2.17,34.69-6.29,38.46,7.81c0,0-1.17-0.42-1.17-0.42
                c7.68-6.6,34.51-5.37,44.99-5.03c-0.96,0.61-8.76,5.31-9.95,6.05c0,0-0.45-1.14-0.45-1.14c10.34-1.59,20.49,2.34,29.86,6.24
                c2.86,1.3,7.05,3.19,8.98,4.42c3.58,0.45,7.13,1,10.68,1.65c3.54,0.66,7.07,1.41,10.54,2.47c2.57,0.91,5.57,1.61,7.3,3.97
                c2.67,3.48,6.57,5.86,10.27,8.2c4.19,2.44,8.55,4.86,13.33,5.8c0,0,1.33,0.17,1.33,0.17c1.98,0.37,3.37,1.78,4.64,3.18
                c2.41,2.78,4.2,5.94,5.9,9.12c1.67,3.2,3.18,6.47,4.54,9.81l-0.4-0.29c4.51,0.57,8.99,2.49,11.85,6.12
                c3.09,4.22,5.58,9.09,6.24,14.33c0.08,0.48,0.63,1.26,0.91,1.75c7.39,11.03,15.7,21.46,23.74,32.02c0,0,0.07,0.09,0.07,0.09
                l0.01,0.11l1.74,24.49l-0.18-0.28c2.45,1.71,2.95,4.68,3.63,7.33c0.57,2.66,0.89,5.36,1.12,8.05c0.46,5.59,0.54,11.19,0.4,16.79
                c-0.9-0.66-1.83-1.28-2.87-1.63c-0.42-0.14-0.86-0.2-1.19-0.08c-1.1,0.67-0.53,2.42-0.36,3.55c1.02,4.62,2.77,9.06,4.42,13.49
                c-0.01,0.02,0.28,15.35,0.27,15.37c-0.27-0.16-6.72,71.44-7.44,70.34c32.6-21.62,46.11-62.22,48.96-99.67
                c0.17-2.94,0.33-5.9,0.17-8.84c-0.89-5.83-1.21-11.74-1.54-17.62L1246.05,417.16L1246.05,417.16z M1246.72,428.51
                c0.28,4.56,0.62,9.12,1.32,13.64c0.16,2.95,0.01,5.9-0.15,8.85c-2.76,37.53-16.22,78.21-48.84,99.96c0,0-0.31,0.2-0.31,0.2
                l0.04-0.37l6.99-70.52c0,0.15-0.31-15.34-0.31-15.31c-1.64-3.98-3.37-8.85-4.44-13.39c-1.28-5.07,1.58-5.22,4.91-2.8
                c0,0-0.49,0.24-0.49,0.24c0.11-5.38,0-10.77-0.45-16.13c-0.38-3.97-0.82-8.04-2.24-11.79c-0.52-1.29-1.21-2.51-2.35-3.15
                c0,0-0.01-0.18-0.01-0.18l-1.8-24.49l0.08,0.21c-8.1-10.57-16.43-20.97-23.87-32.03c-0.45-0.78-0.86-1.21-1.05-2.19
                c-0.67-4.99-3.12-9.76-6.09-13.79c-2.68-3.4-7-5.23-11.21-5.75c0,0-0.29-0.03-0.29-0.03l-0.11-0.26
                c-2.29-5.25-8.63-20.46-14.64-21.51c-5.34-0.63-10.18-3.4-14.77-6.02c-3.09-1.87-6.1-3.89-8.79-6.39
                c-1.36-1.14-1.99-2.86-3.68-3.68c-4.72-2.41-10.12-3.31-15.3-4.35c-3.58-0.69-7.24-1.12-10.82-1.68
                c-5.93-3.18-12.15-5.94-18.56-8.02c-6.29-1.99-13.11-3.64-19.69-2.49c0,0-3.39,0.65-3.39,0.65c2.05-1.22,8.94-5.5,11.09-6.78
                c0,0,0.3,1.18,0.3,1.18c-9.79-0.23-34.52-1.14-41.86,4.83c0,0-0.95,0.88-0.95,0.88c-0.36-5.04-5.21-8.13-9.79-9.16
                c-8.87-2.08-18.37-0.35-27.2,1.36c-3.56,0.84-7.18,1.82-10.22,3.86c0,0-0.62,0.49-0.62,0.49c-0.17,0.18-0.39,0.35-0.52,0.54
                c-0.08,0.09-0.16,0.18-0.21,0.27c-0.06,0.07-0.12,0.22-0.11,0.17l-0.83,0.55l-19.77-2.51l0.85-1c0.17,0.67,1.95,6.39,2.08,6.9
                c0,0-1.05,0.02-1.05,0.02l-21.83,0.32l0.79-0.98l1.19,5.9l0.14,0.7c-7.24,2.58-14.39,5.5-21.43,8.63
                c-13.56,6.11-26.94,13.01-38.82,21.99c-5.78,4.51-11.41,9.57-15.12,15.92c0,0-0.61-1.38-0.61-1.38l6.79,1.47l2.55,0.55
                c-7.61,3.92-15.13,8.53-21.21,14.6c-1.77,1.83-3.41,3.8-4.56,5.99l-1.08-1.41c3.64-0.77,11.16-2.48,14.62-3.22
                c-9.43,6.1-24.06,16.66-24.58,28.66c0,0-1.14-1.03-1.14-1.03c1.78-0.19,6.27-0.62,7.85-0.79c-6.1,11.26-11.73,22.84-16.63,34.69
                c-4.6,11.2-8.72,22.66-9.79,34.81c-1.67,15.13-0.47,30.39,0.18,45.61c0,0.8,0,1.83-0.3,2.63c-1.88,4.98-5.49,9.22-7.09,14.2
                c0,0-0.08,0.41-0.08,0.41c0.02,0.15,0,0.25,0.07,0.38c0.32,0.47,0.92,0.7,1.66,0.96c1.66,0.56,3.75,0.56,5.52,0.6
                c0,0-0.15,1.33-0.15,1.33L801.31,548l-0.2,1.79l-1.62-0.81l-25.98-12.95l-0.21-0.11l-0.15-0.16
                c-5.22-5.92-10.41-12.82-11.08-20.95c0,0,0.08-1.85,0.08-1.85c0.25-2.56-0.21-5.09-0.95-7.64c-1.19-4.18-3.01-8.15-4.75-12.16
                c-0.65-1.57-1.38-3.1-1.88-4.77c-0.16-0.6-0.37-1.15-0.33-2.13c0.01-0.42,0.24-0.93,0.54-1.28c0.06-0.07,0.01-0.03-0.02,0.01
                c-0.03,0.03-0.06,0.07-0.08,0.1c-0.11,0.22-0.06,0.11-0.08,0.14c0.02-0.05,0.06-0.31,0.08-0.54c0.22-19.09-2.53-38.74-1.63-57.85
                c0.21-1.85,0.15-4.31,1.62-5.78c-0.13,0.09-0.03,0.04,0.05-0.17c0.08-0.16,0.16-0.4,0.24-0.62c1.19-4.04,1.72-8.54,2.45-12.76
                c2.79-17.48,5.3-35.08,10.69-52.05c2.93-8.36,6.2-18.09,14.06-23.14c0,0-0.66,0.76-0.66,0.76c2.86-6.84,5.89-13.55,9.12-20.21
                c3.24-6.64,6.65-13.22,10.52-19.57c2.98-4.78,6.09-9.61,10.33-13.48c0,0,1.16-0.96,1.16-0.96l1.3-0.87
                c0.79-0.4,1.83-0.9,2.67-1.22c6.43-3,12.11-7.78,17.39-12.56c4.09-3.95,8.52-8.01,11.56-12.79c0,0,0.06-0.1,0.05-0.1
                c0,0,0.01,0,0.01,0l0.01-0.1c0.02-0.1,0.04-0.36,0.06-0.56c0.48-8.34,1.52-16.69,3.66-24.82c3.72-14.51,10.9-28.46,22.11-38.65
                c0,0-0.26,0.29-0.26,0.29c1.52-2.26,2.79-4.99,3.73-7.59c0.97-2.71,1.57-5.42,1.87-8.33c0.21-1.75,0.84-3.49,1.86-5
                c3.2-4.2,8.42-5.62,13.46-5.84c0,0-1.05,0.33-1.05,0.33l27.12-19.21l0.85,3.37c-3.2-0.42-6.31-0.93-9.48-1.72
                c-2.01-0.58-4.02-0.97-5.72-2.49c-0.58-0.57-0.97-1.44-0.87-2.31c0.08-0.73,0.51-1.39,1.02-1.87c3.96-3.28,9.17-3.67,13.41-5.68
                c0.13-0.07,0.22-0.12,0.29-0.17c0.03-0.01,0.06-0.09-0.14,0.19c-0.05,0.09-0.05,0.12-0.12,0.3c-0.02,0.08-0.04,0.16-0.05,0.24
                c-0.01,0.06,0,0.21,0,0.14l0-0.29l0-0.59l-0.01-4.7l1.73,1.9c-1.64-0.07-17.43-1.68-18.91-1.76c1.35-2.08,5.77-8.27,7.17-10.39
                c0,0,0.03,2.25,0.03,2.25c-1.36-1.95-2.4-3.82-3.41-5.82c-6.05-11.93-7.68-25.6-5.01-38.68c1.85-10.53,3.9-21.26,7.1-31.54
                c3.2-9.63,7.94-23.77,18.46-27.26c4.74-1.35,10.01,0.04,13.95,2.78c9.24,6.83,13.25,18.62,14.54,29.54
                c0.27,2.16,0.47,4.31,0.7,6.41c0.38,2.75,0.51,5.88,2,8.03c2.16,2.86,3.37,6.04,4.59,9.26c1.51,4.15,2.69,8.4,3.33,12.84
                c0.65,4.65,0.83,9.34-1.08,13.88c-0.66,1.03-1.4,2.48-1.53,3.57c-0.07,0.49-0.02,0.87,0.07,1.08c0.37,0.83,0.66,1.85,0.76,2.82
                c0.51,3.31-1.43,6.95-4.73,8.17c-0.11,0.05-0.26,0.11-0.3,0.15l-0.1,0.06c-0.03,0.02,0.01,0,0.01,0
                c0.05-0.07,0.14-0.16,0.17-0.25c0.02-0.05,0.1-0.17,0.12-0.27c0.12-0.33,0.07-0.97-0.15-1.26c-0.11-0.19-0.2-0.23-0.17-0.22
                c4.08,1.43,6.17,5.33,4.79,9.45c-0.2,0.73-0.7,1.77-0.93,2.44c-0.01,0.01-0.01,0.03,0-0.09c0-0.09,0-0.11-0.06-0.39
                c-0.19-0.59-0.75-1.12-1.36-1.15c0.06-0.02,0.36-0.06,0.66-0.16c5.06-1.74,10.14-4.56,15.83-4.71c4.51-0.07,9.23,0.44,13.54,0.05
                c2.27-0.26,4.41-0.63,6.09-2.09c2.79-3.07,6.37-4.14,10.05-5.07c3.13-0.72,6.26-1.12,9.41-1.34c6.4-0.61,12.77,0.58,18.86,2.53
                c3.02,1,6.02,2.16,8.85,3.9c2.01,1.1,3.79,2.84,5.48,4.44c2.62,1.74,5.96,1.65,9.39,1.37c2.82-0.29,5.77-0.92,8.46-1.73
                l-1.65,2.26c0.06-5.28,2.51-11.58,8.68-11.54c0,0,0.5-0.02,0.5-0.02c0.35,0,0.64-0.05,0.95-0.08c0.57-0.07,1.1-0.27,1.14-0.32
                c0.01-0.02-0.13,0.11-0.2,0.35c-0.05,0.16-0.04,0.25-0.01,0.37c0.02,0.06-0.02-0.01,0.08,0.2l0.32,0.48
                c-6.6-8.77-8.54-20.34-7.52-31.04c2.63-23.95,17.85-43.9,33.98-60.66c4.17-4.97,11.7-8.42,16.77-2.25
                c1.83,2.12,3.28,6.51,3.47,7.76c2.81,8.49,4.86,17.01,6.34,25.75c2.87,17.42,3.48,36.39-4.39,52.73c0,0-1.46,3.07-1.46,3.07
                l-1.73,3c-1.18,1.96-2.77,3.97-4.38,5.65c-3.68,3.65-9.37,5.76-14.29,2.8c-0.42-0.2-0.62-0.41-1.06-0.73c0,0,3.64-3.92,3.64-3.92
                c3.17,3.29,6.18,6.41,8.68,10.34c2.9,4.4,0.23,7.81-3.51,10.28c-1.05,0.7-2.15,1.33-3.34,1.9c0,0-0.47,0.06-0.47,0.06l-5.02,0.6
                l2.4-2.69l0.01,5.61l-1.83-2.57c2.74,1.11,4.98,3.03,7.11,4.95c7.63,7.19,13.07,16.36,17.9,25.53c2.14,4.13,4.08,8.65,6.02,12.87
                c3.58,6.67,7.93,13.26,13.15,18.66c1.07,1.01,1.66,1.66,2.67,2.59c5.23,4.67,7.6,12.11,7.97,18.8c0.1,1.4,0.33,2.46,0.62,3.36
                c2.21,6.03,3.3,12.35,4.61,18.52c0,0-2.03-2.22-2.03-2.22c6.18,2.11,11.43,6.26,15.89,10.89c4.26,4.49,7.38,9.64,11.05,14.55
                c3.49,4.84,7.07,9.66,10.31,14.92c2.16,3.7,5.14,8.46,4.32,13.34c0,0-0.16,1.11-0.16,1.11l-0.63,4.43l-1.57-3.05
                c6.72,3.77,19.82,10.84,21.06,18.66c0.24,1.22,0.83,2.53,1.45,3.94c2.09,4.58,4.48,9.23,6.77,13.88
                c11.15,22.64,24.39,50.72,24.06,74.21c0.84,6.23,0.4,12.72-2.49,18.39c-0.14,0.24-0.33,0.44-0.51,0.66
                C1249.48,425.36,1246.72,428.51,1246.72,428.51L1246.72,428.51z"/>
            </g>
          </g>
          <path className="__sitting-totoro-22" d="M776.17,546.01c-0.1,3.87-0.17,7.88,1.34,11.44c1.56,3.7,4.64,6.5,7.63,9.18c-0.95-4.43-0.89-9.07,0.17-13.48
            c0.83-3.41,2.25-6.7,2.55-10.2c0.3-3.5-0.86-7.45-3.94-9.14c-2.82-1.55-6.38-2.44-7.23,1.01
            C775.92,537.97,776.25,542.72,776.17,546.01z"/>
          <path className="__sitting-totoro-22" d="M787.87,560.82c-0.61-5.58-0.15-11.28,1.36-16.69c0.42-1.52,0.95-3.05,1.94-4.28
            c0.99-1.23,2.53-2.11,4.1-1.93c1.58,0.18,2.9,1.42,3.55,2.88s0.73,3.1,0.66,4.69c-0.22,4.89-1.77,9.61-2.93,14.37
            c-1.16,4.76-1.91,9.81-0.54,14.51c-3.13-0.14-5.76-2.67-6.95-5.57C787.87,565.91,787.87,560.82,787.87,560.82z"/>
          <path className="__sitting-totoro-22" d="M1214.32,556.08c-0.53,4.68-2.94,9.23-6.85,11.85c1.33-0.89,0.92-10.77,0.98-12.53
            c0.12-3.46-1.38-8.93-0.25-12.13c1.37-3.88,3.59-0.9,4.37,1.34C1213.88,548.31,1214.77,552.13,1214.32,556.08z"/>
          <path className="__sitting-totoro-22" d="M1204.68,561.57c0.11,5.31-0.04,11.03-3.22,15.28c0.72-6.68-0.99-13.34-1.94-19.99
            c-0.34-2.37-0.57-4.85,0.27-7.09c1.26-3.38,4.05-3.64,4.42,0.03C1204.6,553.69,1204.6,557.67,1204.68,561.57z"/>
          <path className="__sitting-totoro-20" d="M825.75,339.35c-2.32,6.17-4.64,12.34-6.96,18.51c-1.27,3.37-2.53,6.74-3.46,10.22
            c-1.25,4.68-1.88,9.5-2.5,14.3c-0.85,6.54-1.7,13.09-2.55,19.63c1.1-2.1,2.77-3.89,4.78-5.13c0.14,5.97,0.05,11.95-0.27,17.91"/>
          <path className="__sitting-totoro-20" d="M1190.17,332.48c-0.26,8.39,0.52,16.82,2.31,25.03c0.98,4.5,2.28,8.94,2.9,13.5
            c0.46,3.32,0.56,6.68,0.66,10.02c0.07,2.32,0.14,4.64,0.21,6.96"/>
          <path className="__sitting-totoro-20" d="M1182.35,353.09c1.76,7.32,3.21,14.71,4.32,22.16"/>
          <path className="__sitting-totoro-19" d="M957.43,621.12c-0.16-3.55-0.43-7.12-1.31-10.56c-1.81-7.02-6.07-13.11-10.28-19
            c-7.53-10.54-15.21-21.65-26.99-27.05c-0.27-3.15,0.99-6.41,3.33-8.55c0.49-0.45,1.06-0.91,1.19-1.56
            c0.22-1.16-1.02-2.02-2.08-2.55c-6.47-3.19-13-6.4-20.02-8.04c-7.02-1.64-14.67-1.61-21.15,1.55c-0.83-3.28-1.32-6.65-1.44-10.03
            c-8.13,3.22-16.3,6.46-23.86,10.86c-15.55,9.06-26.06,23.57-26.88,41.95c-0.44,9.9,1.7,19.75,4.56,29.24
            c1.84,6.09,4.03,12.19,7.83,17.29c-0.15,0-0.3-0.04-0.45-0.04c0.08,0.05,0.13,0.13,0.21,0.18c3.9,2.54,6.95,6.15,10.05,9.61
            c10.11,11.27,21.38,21.44,32.63,31.58c2.83,2.55,5.67,5.11,8.81,7.26c11.2,7.64,25.59,9.46,39.02,7.65c3.81-0.51,8-1.57,10.07-4.81
            c5.63-8.81,9.82-18.17,14.13-27.69c1.35-2.98,2.63-5.99,3.87-9.02c-0.86,0.44-1.72,0.89-2.55,1.33
            C957.41,640.91,957.86,631,957.43,621.12z"/>
          <path className="__sitting-totoro-23" d="M1204.16,464.95c0,0-4.72-11.8-4.72-15.64c0-3.84,4.72-0.29,4.72-0.29s0.88-28.03-5.02-31.27l-1.77-24.49
            c0,0-24.78-31.86-24.78-33.93s-2.06-8.85-6.2-14.16c-4.13-5.31-11.51-5.9-11.51-5.9s-8.56-21.54-15.05-21.83
            c-6.49-0.29-22.13-9.74-25.08-14.46c-2.95-4.72-28.03-7.67-28.03-7.67s-24.19-13.57-38.65-10.62l8.16-4.97
            c0,0-35.59-1.81-42.38,4.97c0,0-1.18-11.51-24.78-9.15c-23.6,2.36-25.08,7.85-25.08,7.85l-19.77-2.54l1.77,5.9l-21.83,0.3l1.18,5.9
            c0,0-60.48,20.95-75.24,46.62l6.79,1.47c0,0-18.59,9.44-23.9,19.77l9.44-2.07c0,0-20.65,13.28-20.65,26.55l5.9-0.59
            c0,0-15.64,28.32-22.72,52.22c-7.08,23.9-2.06,59.89-2.95,63.43c-0.88,3.54-7.97,13.28-7.38,15.93c0.59,2.65,7.08,2.65,7.08,2.65
            l-3.25,28.91c0,0-0.93,15.59-0.98,22.35c0.5-0.69,1.15-1.27,1.89-1.69c-0.18,4.88,0.32,9.79,1.48,14.53
            c1.62-3.87,3.23-7.73,4.85-11.6c1.56,10.38,3.13,20.77,4.69,31.15c0.79-2.04,1.58-4.08,2.37-6.12c3.31,8.88,6.04,17.97,8.16,27.2
            c0.81-2.38,1.58-4.77,2.34-7.17c0.2-0.63,0.42-1.26,0.62-1.89c1.07,3.23,1.85,8.27,3.39,11.3c1.09,2.15,2.32,4.28,4.05,5.95
            c1.63,1.57,3.77,2.67,6.01,2.75c0.15,0.01,0.3,0.05,0.45,0.04c-3.8-5.1-5.99-11.2-7.83-17.29c-2.86-9.49-5-19.34-4.56-29.24
            c0.82-18.37,11.33-32.89,26.88-41.95c7.56-4.4,15.73-7.64,23.86-10.86c0.12,3.38,0.6,6.75,1.44,10.03
            c6.48-3.15,14.13-3.19,21.15-1.55c7.02,1.65,13.55,4.86,20.02,8.04c1.06,0.52,2.3,1.39,2.08,2.55c-0.13,0.66-0.69,1.11-1.19,1.56
            c-2.33,2.14-3.6,5.39-3.33,8.55c11.78,5.39,19.45,16.5,26.99,27.05c4.21,5.89,8.47,11.99,10.28,19c0.89,3.44,1.16,7.01,1.31,10.56
            c0.43,9.88-0.02,19.79-1.31,29.59c0.84-0.44,1.7-0.89,2.55-1.33c0.49-0.25,0.97-0.51,1.46-0.76c5.48-2.83,11.13-5.71,16.14-9.03
            c-0.15,4.17-1.03,8.31-2.59,12.18c4.08-0.11,8.26-0.24,12.05-1.76c2.91-1.17,5.44-3.12,8.2-4.62c4.68-2.55,10.04-3.83,15.37-3.66
            c8.78,0.29,16.79-0.45,25.34,1.56c1.34,0.31,2.69,0.67,4.07,0.6c2.55-0.13,4.77-1.69,6.83-3.19c0.67,2.3,1.06,4.67,1.17,7.06
            c3.32,0.54,6.86-0.6,9.24-2.97c0.62-0.62,1.07-1.33,1.61-1.99c0.29-0.76,0.64-1.51,0.97-2.26c0.11,0.22,0.21,0.45,0.32,0.68
            c7.34-10.54,6.66-25.61,7.25-38.86c0.23-5.25,5.35-14.13,6.38-19.29c7.35-4.38,7.89-6.8,13.17-13.53
            c6.54-8.34,11.87-18.75,21.79-22.5c2.35-0.89,4.95-1.39,6.87-3.01c1.4-1.18,2.38-2.92,4.05-3.68c3.08-1.4,6.8,1.2,9.99,0.07
            c1.18-0.41,2.13-1.29,3.24-1.87c4.61-2.39,10.24,0.99,15.36,0.06c1.58-0.29,3.12-0.99,4.72-0.88c2.68,0.18,4.63,2.5,6.41,4.52
            c3.71,4.2,8.09,7.81,12.91,10.67c1.89,1.12,4.16,2.79,3.62,4.91c-0.33,1.3-1.63,2.12-2.14,3.36c-1,2.44,1.39,4.79,2.83,7.01
            c1.74,2.68,2.19,5.98,2.59,9.15c0.07,0.52,0.13,1.05,0.2,1.57c0.59,4.68,1.18,9.37,1.77,14.05c0.15,1.2,0.3,2.46-0.1,3.6
            c-0.68,1.93-2.72,3.01-3.96,4.65c-1.95,2.56-1.67,5.99-0.98,9.24c0.14,0.65,0.27,1.31,0.43,1.93c7.22-8.26,10.91-18.98,13.64-29.6
            c2.73-10.62,4.71-21.53,9.19-31.54c0.73-1.63,1.49-3.17,2.27-4.68l6.66-66.28L1204.16,464.95z"/>
          <path className="__sitting-totoro-22" d="M922.6,171.9c-4.11,1.13-7.86,3.61-11.05,7.74c-3.47,4.48-3.73,10.59-3.84,16.25
            c-0.09,4.65,0.62,8.68,5.07,11.11c5.55,3.03,13.57,3.18,19.75,3.44c3.01,0.12,6.1,0.16,8.96-0.79c4.92-1.64,8.54-6.08,10.22-10.99
            c1.3-3.8,2.03-8,1.52-12.02c-0.61-4.82-2.89-6.59-6.64-9.27c-2.54-1.81-5.34-3.23-8.27-4.28
            C932.79,171.12,927.45,170.57,922.6,171.9z"/>
          <path className="__sitting-totoro-22" d="M1074.68,182.41c-3.11,6.16-3.56,14.6,1.16,20.2c1.96,2.33,4.88,3.61,7.74,4.63
            c5.38,1.92,11.1,3.22,16.78,2.62c1.73-0.19,3.47-0.56,4.96-1.46c1.63-0.99,2.83-2.55,3.91-4.12c2.41-3.52,4.43-7.41,5.14-11.62
            s-0.03-8.79-2.67-12.15c-2.39-3.04-6.04-4.78-9.67-6.1c-4.34-1.58-8.84-2.69-13.42-3.32c-7.96-1.09-9.74,4.23-13.22,10.02
            C1075.15,181.53,1074.9,181.96,1074.68,182.41z"/>
          <path className="__sitting-totoro-24" d="M1003.29,199.45c-0.48,0-0.97,0-1.42-0.17c-1.13-0.45-1.56-1.95-1.13-3.09c0.43-1.14,1.49-1.92,2.6-2.41
            c1.9-0.83,4.02-0.97,6.1-1.07c6.84-0.35,13.85-0.52,20.38,1.55c1.07,0.34,2.36,1.19,1.99,2.25c-0.2,0.58-0.82,0.88-1.4,1.07
            c-3.11,1.02-6.58,0.61-9.64,1.8c-0.29,0.11-0.61,0.27-0.74,0.55c-0.12,0.26-0.06,0.57-0.17,0.83c-0.18,0.47-0.77,0.62-1.27,0.68
            c-1.07,0.13-3.79,0.57-4.73-0.06c-0.62-0.41-0.2-1.48-1.09-1.98C1010.96,198.38,1005.44,199.44,1003.29,199.45z"/>
          <path className="__sitting-totoro-20" d="M996.42,189.29c11.76-4.54,24.41-6.77,37.01-6.52"/>
          <path className="__sitting-totoro-20" d="M1035.65,188.79c2.14,0,4.28,0,6.42,0"/>
          <path className="__sitting-totoro-20" d="M1007.08,180.41c-7.6,0.48-15.71,1.15-21.71,5.84c-1.59,1.24-3.07,2.95-3.06,4.97"/>
          <path className="__sitting-totoro-20" d="M1024.26,255.75c-2.52-0.34-5.16-0.47-7.55,0.39c-2.39,0.87-4.5,2.94-4.74,5.47"/>
          <path className="__sitting-totoro-24" d="M927.46,184.22c-1.85,1.93-1.51,5.07-0.59,7.58c0.63,1.71,1.5,3.37,2.83,4.62c1.82,1.72,4.35,2.52,6.83,2.86
            c1.03,0.14,2.08,0.21,3.11,0.03c3.58-0.61,6.18-4.4,5.8-8.02C944.77,184.67,932.51,178.93,927.46,184.22z"/>
          <path className="__sitting-totoro-24" d="M1080.92,183.56c-1,1.07-1.57,2.52-1.42,4.39c0.13,1.58,0.79,3.08,1.69,4.39c2.16,3.14,5.59,5.19,9.17,6.5
            c0.74,0.27,1.51,0.52,2.3,0.56c2.7,0.15,5.06-2.2,5.7-4.83c1.22-4.97-1.1-10.25-5.88-12.48
            C1089.1,180.5,1083.53,180.75,1080.92,183.56z"/>
          <g>
            <path className="__sitting-totoro-19" d="M1072.11,346.86c0.76,0.85,1.97,1.14,3.11,1.17c6.25,0.14,10.92-6.32,17.13-7.03
              c4.16-0.48,8.1,1.72,11.67,3.9c7.95,4.86,15.8,10.1,24.63,13.06c1.56,0.52,3.48,0.91,4.69-0.2c0.96-0.87,1.06-2.4,0.59-3.61
              s-1.4-2.17-2.3-3.1c-6.92-7.11-13.84-14.23-20.76-21.34c-3.3-3.4-7.13-7.03-11.87-7.12c-3.2-0.06-6.19,1.56-8.86,3.33
              C1086.69,328.21,1067.03,341.23,1072.11,346.86z"/>
            <path className="__sitting-totoro-19" d="M1110.81,387.69c-1.69,1.61-3.36,3.58-3.36,5.92c0.01,2.27,1.69,4.29,3.72,5.31s4.37,1.22,6.64,1.3
              c4.49,0.16,9-0.08,13.45-0.73c1.52-0.22,3.09-0.49,4.59-0.15c1.6,0.37,2.95,1.39,4.3,2.32c4.64,3.21,9.79,5.66,15.21,7.24
              c1.77,0.52,4.1,0.74,5.05-0.84c0.38-0.62,0.42-1.39,0.45-2.12c0.19-4.17,0.36-8.46-0.98-12.42c-2.02-5.93-7.25-10.32-13.01-12.79
              c-5.41-2.32-14.01-5.02-19.72-3.31C1121.15,379.23,1115.31,383.4,1110.81,387.69z"/>
            <path className="__sitting-totoro-19" d="M1023.9,388.19c2.15-0.99,4.03-2.48,6.09-3.67c6.82-3.93,15.65-4.11,22.63-0.47
              c3.12,1.63,5.89,3.96,9.21,5.11c3.27,1.13,6.95,1,10.13-0.35c0.67-0.28,1.35-0.65,1.73-1.27c0.33-0.55,0.39-1.22,0.38-1.86
              c-0.07-3.48-1.91-6.75-4.4-9.19c-2.49-2.43-5.6-4.13-8.75-5.62c-5.96-2.83-12.3-5.1-18.89-5.42c-6.59-0.33-13.47,1.46-18.35,5.9
              c-3.12,2.84-6.89,7.93-8.57,11.76C1012.67,388.64,1019.95,390.01,1023.9,388.19z"/>
            <path className="__sitting-totoro-19" d="M1006.6,332.61c10.19,0.71,19.11,8.89,29.29,8.07c0.93-0.08,2.05-0.38,2.3-1.28c0.16-0.57-0.1-1.17-0.37-1.7
              c-5.48-10.71-17-17.24-28.75-19.86c-11.23-2.51-22.15-1.36-31.46,5.71c-6.32,4.81-9.7,10.86-6.62,18.95
              C983.42,343.44,993.81,331.72,1006.6,332.61z"/>
            <path className="__sitting-totoro-19" d="M927.53,394.87c-0.06,0.43-0.09,0.88,0.11,1.26c0.3,0.59,1.04,0.81,1.71,0.84c2.86,0.17,5.41-1.77,7.31-3.91
              s3.52-4.61,5.92-6.16c4.86-3.14,11.32-1.59,16.63,0.73c1.68,0.73,3.39,1.55,5.22,1.62c2.73,0.11,5.47-1.44,8.09-0.68
              c1.98,0.57,3.35,2.29,4.82,3.73c1.47,1.44,3.49,2.75,5.46,2.16c3.08-7.04-1.03-15.73-7.64-19.66c-6.6-3.93-14.88-3.94-22.39-2.31
              c-5.76,1.26-11.57,3.61-15.97,7.64C933.07,383.56,928.2,389.79,927.53,394.87z"/>
            <path className="__sitting-totoro-19" d="M864.78,366.09c4.16,1.57,8.88-0.29,12.44-2.96c3.56-2.68,6.47-6.17,10.22-8.57
              c5.64-3.62,12.61-4.42,19.31-4.61s13.51,0.15,19.99-1.53c1.43-0.37,3.1-1.15,3.21-2.63c0.05-0.65-0.25-1.28-0.58-1.85
              c-2.31-3.86-6.74-5.9-11.03-7.24c-3.66-1.15-7.42-1.96-11.17-2.77c-4.28-0.93-8.62-1.86-13-1.68
              C878.05,332.91,867.94,352.31,864.78,366.09z"/>
            <path className="__sitting-totoro-19" d="M857.15,396.21c-4.52,6.66-8.23,13.87-11.05,21.41c6.23,0.52,12.64-1.51,17.45-5.5
              c3.39-2.82,6.29-6.72,10.61-7.58c2.35-0.47,4.77,0.06,7.16,0.34c5.27,0.64,10.67,0.07,15.7-1.64c1.02-0.35,2.18-0.93,2.32-1.99
              c0.14-1.05-0.78-1.9-1.63-2.53c-6.78-5.01-14.69-8.47-22.96-10.11C866.72,387.02,861.82,389.32,857.15,396.21z"/>
          </g>
          <circle className="__sitting-totoro-17" cx="1083.65" cy="187.38" r="2.88"/>
          <circle className="__sitting-totoro-17" cx="930.54" cy="188.79" r="2.43"/>
          <path className="__sitting-totoro-19" d="M999.05,681.42c-18.9-0.98-37.9-1.28-55.42,5.36c-2.07,0.78-4.12,1.37-6.17,1.87
            C937.47,688.65,967.75,679.58,999.05,681.42z"/>
          <path className="__sitting-totoro-26" d="M999.53,681.44c-0.16-0.01-0.32-0.01-0.48-0.02C999.21,681.43,999.37,681.44,999.53,681.44
            C999.52,681.45,999.53,681.44,999.53,681.44z"/>
          <path className="__sitting-totoro-19" d="M1015.13,682.28c-5.17-0.24-10.39-0.56-15.61-0.83c-0.16-0.01-0.31-0.02-0.47-0.03
            c-31.3-1.84-61.58,7.23-61.58,7.23c-1.57,5.21-5.73,9.34-7.34,14.55c-0.85,2.75-0.94,5.67-0.88,8.54
            c0.32,14.62,4.42,28.92,9.68,42.56c5.6,14.51,15.33,24.94,30.11,30.43c10.14,3.77,21.08,4.9,31.9,4.99
            c7.18,0.06,14.65-0.4,20.93-3.87c2.76-1.53,5.79-3.69,8.75-2.61c0.63,0.23,1.2,0.6,1.84,0.81c2.26,0.75,4.71-0.65,6.24-2.48
            s2.49-4.07,4.01-5.89c4.71-5.63,8.27-11.9,11.76-18.32c1.91-3.52,4.26-6.75,6.39-10.14c1.07-1.7,2.1-3.42,2.97-5.26
            c2.09-4.41,3.65-9.04,4.7-13.81c0.9-4.07,2.63-7.67,3.4-11.81c1.78-9.47,1.44-19.16,2.51-28.7
            C1055.57,686.31,1034.73,683.19,1015.13,682.28z"/>
          <path className="__sitting-totoro-5" d="M929.06,712.79c0.31,14.41,4.35,28.51,9.54,41.96c5.52,14.3,15.12,24.58,29.68,30
            c9.99,3.72,20.78,4.83,31.45,4.92c7.07,0.06,14.44-0.39,20.63-3.82c2.72-1.5,5.71-3.63,8.63-2.57c0.62,0.23,1.19,0.59,1.81,0.8
            c2.22,0.74,4.64-0.64,6.15-2.44c1.51-1.8,2.45-4.01,3.95-5.81c4.65-5.55,8.15-11.73,11.59-18.06c1.89-3.47,4.19-6.66,6.3-9.99
            c-9.47,1.37-23.87,18.96-46.91,6.99c-41.8-21.71-13.66-71.72-13.55-71.84c-0.16-0.01-0.31-0.02-0.46-0.03
            c-30.86-1.82-60.71,7.13-60.71,7.13c-1.55,5.14-5.65,9.21-7.24,14.34C929.09,707.08,929,709.96,929.06,712.79z"/>
          <path className="__sitting-totoro-28" d="M859.66,286.24c5.3-10.95,16.85-17.5,24.1-27.3c6.34-8.57,9.06-19.18,11.65-29.5
            c3.03-12.06,6.06-24.12,9.08-36.18c1.27-5.07,2.58-10.24,5.34-14.69c2.19-3.53,5.2-6.48,8.15-9.41
            c5.22-5.2,10.35-10.48,15.39-15.86c5.24-5.59,10.67-11.99,10.85-19.62c0.2-8.59-6.13-14.89-8.55-22.69
            c-1.36-4.41-4.46-8.63-5.13-13.15c-0.87-5.86,0.48-11.97-0.82-17.77c-1.23-5.51-2.67-10.08-2.81-15.88
            c-0.4-16.26-0.73-32.97,4.91-48.25c0.02-0.07,0.01-0.16-0.14-0.25c-16.37,0.43-23.82,37.77-27.69,60.78
            c-3.87,23.01,8.63,39.83,8.63,39.83l-5.36,7.67l15.78,1.47c0,0,0,3.84,0,5.61c0,1.77-11.61,3.54-14.29,6.49
            c-2.68,2.95,14.29,5.02,14.29,5.02l-27.39,19.18c0,0-12.8,0-13.7,9.44c-0.89,9.44-5.95,16.52-5.95,16.52
            C850.4,210.72,851,247.9,850.4,250.85c-0.59,2.95-19.06,22.42-31.56,27.14c-12.51,4.72-33.35,54.88-33.35,54.88
            c-19.65,11.21-23.82,85.56-27.59,88.51c-3.78,2.95,1.99,61.96,0.2,63.73c-1.79,1.77,8.93,17.7,7.74,27.14
            c-1.19,9.44,10.72,21.83,10.72,21.83l21.68,10.74l4.53,2.24l3.27-28.91c0,0-6.55,0-7.15-2.65c-0.59-2.65,6.55-12.39,7.44-15.93
            c0.89-3.54-4.17-39.54,2.98-63.43c7.15-23.9,22.93-52.22,22.93-52.22l-5.95,0.59c0-13.28,20.84-26.55,20.84-26.55l-9.53,2.07
            c2.09-4.03,6.23-7.92,10.49-11.17c-0.02-0.11-0.02-0.21-0.04-0.32C844.63,327.11,850.19,305.79,859.66,286.24z"/>
          <path className="__sitting-totoro-28" d="M1081.4,126.5c0.99-0.82,1.1-2.26,1.42-3.5c0.78-3.01,3.33-5.49,6.37-6.17c1.94-6.24,1.75-13.12-0.54-19.23
            c2.01-0.48,2.91-2.95,2.59-4.99c-0.32-2.04-1.48-3.83-2.22-5.76c-2.39-6.3-0.07-13.32,2.41-19.58c3.23-8.17,6.78-16.21,10.61-24.11
            c3.26-6.73,10.59-15.57,11.9-22.83c0.13-0.74,0.14-1.3,0.2-1.93c-4.05-1.33-8.26,2.6-8.26,2.6c-56.9,56.64-28.28,90.28-28.28,90.28
            s1.48,2.95-4.72,2.95c-6.2,0-6.2,9.15-6.2,9.15s-1.04,0.33-2.65,0.72c4.34,1.66,9.23,2.38,13.88,2.93
            C1079.11,127.17,1080.46,127.28,1081.4,126.5z"/>
          <path className="__sitting-totoro-27" d="M834.65,359.74l9.44-2.07c0,0-20.65,13.28-20.65,26.55l5.9-0.59c0,0-15.64,28.32-22.72,52.22
            c-7.08,23.9-2.06,59.89-2.95,63.43c-0.88,3.54-7.97,13.28-7.38,15.93s7.08,2.65,7.08,2.65l-3.25,28.91c0,0-0.93,15.59-0.98,22.35
            c0.5-0.69,1.15-1.27,1.89-1.69c-0.18,4.88,0.32,9.79,1.48,14.53c1.62-3.87,3.23-7.73,4.85-11.6c1.56,10.38,3.13,20.77,4.69,31.15
            c0.79-2.04,1.58-4.08,2.37-6.12c3.31,8.88,6.04,17.97,8.16,27.2c0.81-2.38,1.58-4.77,2.34-7.17c0.2-0.63,0.42-1.26,0.62-1.89
            c1.07,3.23,1.85,8.27,3.39,11.3c1.09,2.15,2.32,4.28,4.05,5.95c1.63,1.57,3.77,2.67,6.01,2.75c0.15,0.01,0.3,0.05,0.45,0.04
            c-3.8-5.1-5.99-11.2-7.83-17.29c-2.86-9.49-5-19.34-4.56-29.24c0.82-18.37,11.33-32.89,26.88-41.95
            c7.56-4.4,15.73-7.64,23.86-10.86c0.12,3.38,0.6,6.75,1.44,10.03c6.48-3.15,14.13-3.19,21.15-1.55c2.42,0.57,4.77,1.37,7.1,2.24
            c-0.4-7.82-8.86-15.49-15.17-21.79c-16.72-16.71-21.45-44.23-11.26-65.56c-3.27,0.59-6.54,1.19-9.81,1.78
            c1.33-4.18,2.65-8.36,3.98-12.54c-9.62,3.03-20.8,0.34-28-6.72s-10.09-18.2-7.24-27.87c-0.09,0.18-0.17,0.37-0.26,0.55
            c-1.9,4.05-3.79,8.06-5.63,11.95c1.88-3.98,3.75-7.97,5.63-11.95c8.94-19.13,17.92-39.78,21.47-59.84
            c-4.2-1.95-8.4-4.54-12.65-7.01C843.25,349.66,837.3,354.59,834.65,359.74z"/>
          <path className="__sitting-totoro-27" d="M1044.28,593.4c-8.11,3.71-13.44,11.52-18.36,18.95c0.61-9.02-11.08-14.3-19.84-12.08
            c-8.76,2.23-15.66,8.82-23.72,12.91c-19.95-10.75-37.63-25.67-51.59-43.52c-3.51-4.49-7.18-9.37-11.43-13.18
            c-1.02,1.81-1.56,3.9-1.38,5.98c11.78,5.39,19.45,16.5,26.99,27.05c4.21,5.89,8.47,11.99,10.28,19c0.89,3.44,1.16,7.01,1.31,10.56
            c0.43,9.88-0.02,19.79-1.31,29.59c0.84-0.44,1.7-0.89,2.55-1.33c0.49-0.25,0.97-0.51,1.46-0.76c5.48-2.83,11.13-5.71,16.14-9.03
            c-0.15,4.17-1.03,8.31-2.59,12.18c4.08-0.11,8.26-0.24,12.05-1.76c2.91-1.17,5.44-3.12,8.2-4.62c4.68-2.55,10.04-3.83,15.37-3.66
            c8.78,0.29,16.79-0.45,25.34,1.56c1.34,0.31,2.69,0.67,4.07,0.6c2.55-0.13,4.77-1.69,6.83-3.19c0.67,2.3,1.06,4.67,1.17,7.06
            c3.32,0.54,6.86-0.6,9.24-2.97c0.62-0.62,1.07-1.33,1.61-1.99c0.29-0.76,0.64-1.51,0.97-2.26c0.11,0.22,0.21,0.45,0.32,0.67
            c7.34-10.54,6.66-25.61,7.25-38.86c0.03-0.7,0.16-1.48,0.35-2.3C1061.39,591.71,1051.47,590.1,1044.28,593.4z"/>
          <path className="__sitting-totoro-28" d="M827.94,587.85c-0.44,9.9,1.69,19.75,4.52,29.24c1.82,6.09,3.99,12.19,7.76,17.29c-0.15,0-0.3-0.04-0.45-0.04
            c0.07,0.05,0.13,0.13,0.2,0.18c3.86,2.54,6.88,6.15,9.96,9.61c10.02,11.27,21.18,21.44,32.32,31.58c2.8,2.55,5.61,5.11,8.73,7.26
            c11.1,7.64,25.34,9.46,38.65,7.65c3.78-0.51,7.92-1.57,9.97-4.81c5.58-8.81,9.73-18.17,13.99-27.69c1.33-2.98,2.6-5.99,3.83-9.02
            c-0.85,0.44-1.7,0.89-2.53,1.33c0.92-7.09,1.25-14.24,1.29-21.39c-0.17-0.14-0.31-0.31-0.49-0.45c-3.72,3.3-7.44,6.59-11.16,9.89
            c-1.29-1.22-2.13-2.9-2.33-4.67c-3.97,3.58-7.94,7.16-11.9,10.74c-0.52-2.2-1.46-4.3-2.76-6.14c-10.32,4.64-21.43,7.49-32.69,8.4
            c-7.7,0.62-16.69-0.29-21.04-6.73c-2.06-3.06-4.39-7.76-7.79-6.39c1.65-5.75,4.07-11.27,7.17-16.37
            c-4.39,2.78-9.24,4.82-14.29,5.99c-0.39-8.77-0.77-17.55-1.16-26.32c-0.07-1.55-0.16-3.17-0.98-4.48
            c-0.74-1.19-1.98-1.96-2.87-3.03c-3.72-4.48-0.04-11.23-0.58-17.05c-3.05,0.76-6.09,1.52-9.14,2.29c2.12-4.49,3.67-9.25,4.62-14.13
            c-1.94,0.93-3.88,1.85-5.81,2.78c1.3-0.62,9.83-12.61,9.91-14.11c0.04-0.78-0.08-1.44-0.28-2.06
            C838.33,556.3,828.72,570.28,827.94,587.85z"/>
          <path className="__sitting-totoro-19" d="M902.78,612.95c1.62-1.19,2.85-2.85,4.44-4.08c3.33-2.57,7.99-2.94,12.01-1.73c4.03,1.2,7.5,3.8,10.54,6.7
            c11.32,10.83,17.46,26.7,17,42.36c-0.15,5.28-1.05,10.64-3.5,15.33c-4.41,8.45-13.75,13.86-23.27,14.32s-18.99-3.74-25.72-10.5
            c-4.58-4.61-7.93-10.28-11.24-15.88c-4.01-6.81-8.02-13.62-12.04-20.43c-0.35-0.6-0.72-1.25-0.63-1.94
            c0.14-1.19,1.44-1.78,2.43-2.46c4.9-3.37,5.62-10.43,10.48-14.26C889.15,615.75,896.91,617.26,902.78,612.95z"/>
          <g>
            <path className="__sitting-totoro-17" d="M927.44,654.95c2.68-1.43,6.27-2.65,8.5-0.58c1.04,0.96,1.47,2.41,1.68,3.8c0.77,5.16-1.15,10.88-5.48,13.79
              c-3.24,2.18-7.32,2.58-11.21,2.92C903.79,676.4,921.64,658.05,927.44,654.95z"/>
            <path d="M926.7,653.57c5.2-2.88,11.56-2.58,12.19,4.58c0.55,4.8-0.95,9.89-4.53,13.26c-3.65,3.38-8.53,3.83-13.14,4.14
              c-6.08,0.57-10.41-1.66-6.59-8.07c2.38-3.91,5.63-7.09,9.1-9.98c1.17-0.94,2.38-1.84,3.7-2.55L926.7,653.57L926.7,653.57z
               M927.44,654.95c-2.56,1.56-4.72,3.67-6.78,5.82c-2.18,2.42-8.75,9.64-5.65,12.69c3.02,1.75,7.19,0.51,10.49,0.11
              c5.93-0.88,10.29-4.99,10.94-10.94c0.39-3.25,0.04-8.96-4.55-7.78c-1.23,0.25-2.5,0.82-3.7,1.5L927.44,654.95L927.44,654.95z"/>
          </g>
          <g>
            <path className="__sitting-totoro-17" d="M890.16,591.87c-1.93,1.21-1.33,4.13-0.46,6.24c1.23,3,12.04,30.98,16.52,21.23
              C908.3,614.78,892.43,590.45,890.16,591.87z"/>
            <path d="M890.99,593.2c-1.58,1.22,0.24,4.76,0.75,6.31c2.1,5.43,4.33,10.9,7.27,15.91c1.09,1.77,2.23,3.62,3.9,4.8
              c1.14,0.74,1.9,0.22,2.5-0.92c0.2-0.32,0.24-0.44,0.28-0.76c0.09-1.71-0.57-3.38-1.17-5.04c-2.78-6.75-6.57-13.2-11.05-18.98
              c-0.7-0.84-1.42-1.7-2.3-2.36c-0.29-0.19-0.65-0.45-1-0.3L890.99,593.2L890.99,593.2z M890.16,591.87
              c0.35-0.18,0.73,0.08,1.03,0.25c0.92,0.63,1.68,1.46,2.42,2.28c4.79,5.65,8.86,11.92,11.99,18.66c0.88,2.23,2.37,5.18,0.81,7.48
              c-0.63,1.02-1.8,1.88-3.06,1.59c-2.82-0.71-4.44-3.5-6.01-5.67c-1.65-2.53-3.07-5.16-4.4-7.82c-1.33-2.67-2.57-5.37-3.75-8.1
              c-0.96-2.27-2.18-4.59-1.71-7.2c0.15-1.01,0.84-2.18,1.84-2.81L890.16,591.87L890.16,591.87z"/>
          </g>
          <g>
            <path className="__sitting-totoro-17" d="M872.51,607.31c-0.32-0.14-0.66-0.27-1.01-0.23c-1.09,0.15-1.13,1.71-0.85,2.77
              c1.84,6.81,5.01,13.27,9.29,18.88c1.09,1.43,2.15,2.48,3.89,2.86C888.16,622.91,880.52,610.82,872.51,607.31z"/>
            <path d="M871.89,608.75c-0.25-0.13-0.36-0.13-0.21-0.13c0.11,0.02,0.36-0.15,0.39-0.24c0.03-0.08-0.04,0.03-0.05,0.19
              c-0.07,0.4,0.05,0.94,0.16,1.34c1.73,6.87,4.84,13.51,9.15,19.13c0.75,0.9,1.6,1.55,2.68,1.8l-0.85,0.41
              c4.25-8.7-2.67-19.91-10.64-23.93C872.51,607.31,871.89,608.75,871.89,608.75L871.89,608.75z M872.51,607.31
              c8.62,3.83,16.11,15.22,12.02,24.62c-0.15,0.33-0.51,0.49-0.85,0.41c-2.88-0.49-4.49-3.13-6.09-5.21
              c-3.71-5.11-6.54-10.86-8.34-16.92c-0.91-2.75,0.85-5.72,3.89-4.34C873.14,605.88,872.51,607.31,872.51,607.31L872.51,607.31z"/>
          </g>
          <g>
            <path className="__sitting-totoro-17" d="M922.38,619.22c0.67-0.2,1.33-0.55,1.76-1.11c0.58-0.75,0.67-1.75,0.72-2.69
              c0.48-8.58-0.52-17.24-2.95-25.49c-2.02,0.88-3.37,2.89-3.99,5.01c-0.62,2.12-0.6,4.36-0.58,6.56
              C917.39,607.94,914.18,619.22,922.38,619.22z"/>
            <path d="M922.15,617.67c-0.04,0-0.08,0.01-0.12,0.02c0.26-0.07,0.67-0.25,0.85-0.45c0.79-1.13,0.52-3.17,0.69-4.59
              c0.34-7.57-0.5-15.16-2.46-22.49l1.12,0.52c-1.13,0.46-2.09,1.36-2.77,2.48c-2.22,3.62-1.28,8.2-1.92,12.23
              c-0.45,4.78-2.26,13.66,4.83,13.83C922.38,619.22,922.15,617.67,922.15,617.67L922.15,617.67z M925.22,619.17l-2.84,0.06
              c-7.21,0.19-5.9-9.06-5.65-13.9c0.4-5.56-1.24-13.07,4.85-16.15c0.45-0.21,1,0.04,1.12,0.52c2.37,7.41,3.61,15.22,3.64,23.01
              C926.21,614.93,926.72,617.16,925.22,619.17L925.22,619.17z"/>
          </g>
          <path className="__sitting-totoro-28" d="M1083.01,685.84c3.88,1.18,7.91,1.85,12.04,2.08c3.73,0.2,7.47,0.45,11.2,0.75
            c5.43,0.43,17.84-5.43,16.22-6.65c-12.93-9.68-24.74-53.58-28.27-70.57c-1-4.84-2.5-9.77-1.57-14.62c0.66-3.41,2.48-6.55,2.77-10
            c0.29-3.46-1.95-7.6-5.41-7.39c-0.82-0.98,0.24-2.42,1.33-3.08c1.09-0.67,2.46-1.45,2.39-2.72c-0.03-0.49-0.45-0.65-0.89-0.68
            c-0.32,0.29-0.66,0.62-0.94,0.86c-0.56-0.55,0.23-0.92,0.94-0.86c6.54-5.99,19.83-20.83,18.81-27.96
            c-1.51,0.69-3.16,1.13-4.73,1.72c-9.91,3.75-15.25,14.16-21.79,22.5c-5.28,6.74-5.82,9.15-13.17,13.53
            c-1.03,5.16-6.14,14.03-6.38,19.29c-0.59,13.25,0.09,28.32-7.25,38.86c0.13,0.26,0.24,0.54,0.38,0.8c-0.13-0.26-0.25-0.54-0.38-0.8
            c-0.39,0.55-0.85,1.06-1.29,1.59c-1.18,3.14-1.94,6.37-1.71,9.7c0.24,3.37,1.47,6.59,2.75,9.72c3.4,8.36,7.37,14.96,15.25,19.66
            C1076.4,683.4,1079.65,684.81,1083.01,685.84z"/>
          <path className="__sitting-totoro-19" d="M1070.11,634.84c-1.2,1.41-1.67,3.29-2.1,5.09c-0.92,3.83-1.84,7.67-2.76,11.5c-1.1,4.57-2.19,9.42-0.74,13.9
            c1.74,5.39,6.77,9.01,11.83,11.56c9.14,4.61,19.39,6.99,29.63,6.89c3.65-0.04,7.36-0.4,10.72-1.82c5.67-2.4,9.69-7.49,13.2-12.55
            c5.07-7.31,9.75-15.48,9.82-24.37c0.02-1.94-0.21-3.93-1.11-5.64c-0.9-1.71-2.41-3-3.88-4.25c-2.76-2.35-5.52-4.7-8.28-7.05
            c-2.09-1.78-4.21-3.58-6.69-4.75c-5.98-2.83-12.99-1.64-19.55-2.45c-4.46-0.55-8.9-2.06-13.34-1.41c-5.82,0.85-5.95,4.37-7.92,8.41
            C1077.09,631.65,1072.89,631.55,1070.11,634.84z"/>
          <g>
            <path className="__sitting-totoro-17" d="M1076.39,660.51c0.88-1.03,2.33-1.3,3.67-1.47c6.19-0.75,12.51-0.37,18.56,1.1c1.68,0.41,3.4,0.93,4.69,2.09
              c1.88,1.68,2.48,4.34,2.93,6.82c0.48,2.59,0.75,5.7-1.22,7.45c-1.58,1.41-3.97,1.31-6.07,0.97
              C1094.36,676.73,1069.51,668.57,1076.39,660.51z"/>
            <path d="M1075.2,659.49c1.61-1.76,3.78-1.83,5.77-2.03c7.02-0.48,14.1,0.07,20.75,2.53c3.69,1.4,5.02,5.68,5.44,9.17
              c0.41,2.54,0.6,6-1.69,7.91c-4.35,3.42-13.11-1.11-17.66-2.82c-4.43-2.08-16.48-7.58-11.43-13.75
              C1076.39,660.51,1075.2,659.49,1075.2,659.49L1075.2,659.49z M1076.39,660.51c-4.75,6.22,7.31,11.15,11.73,13.03
              c4.04,1.31,15.74,6.78,17.44,0.63c0.38-1.43,0.17-3.08-0.14-4.69c-0.59-3.05-1.41-6.37-4.56-7.5c-6.91-2.17-14.79-2.48-21.91-1.21
              c-0.67,0.18-1.14,0.45-1.37,0.76L1076.39,660.51L1076.39,660.51z"/>
          </g>
          <g>
            <path className="__sitting-totoro-17" d="M1079.2,611.26c-0.91,4.46-1.62,8.96-2.14,13.48c-0.16,1.37-0.02,3.19,1.31,3.56
              c0.64,0.18,1.31-0.08,1.89-0.39c5.09-2.72,7-8.95,8.21-14.59c0.61-2.84,1.17-5.7,1.4-8.6c0.34-4.17-0.91-9.64-5.29-6.4
              C1081.15,600.86,1079.98,607.39,1079.2,611.26z"/>
            <path d="M1080.73,611.57c-0.95,4.87-2.31,9.92-2.42,14.87c0.09,0.37,0.12,0.6,0.4,0.73c0.72,0.11,2.26-0.97,2.94-1.54
              c3.27-2.81,4.67-7.04,5.77-11.18c0.83-4.08,3.89-13.56,0.27-16.54c-0.52-0.3-1.21-0.21-1.83,0.07c-4.73,2.45-5.43,8.6-6.66,13.29
              C1079.2,611.26,1080.73,611.57,1080.73,611.57L1080.73,611.57z M1079.2,611.26c1.03-4.88,1.53-11.22,6.38-13.92
              c0.69-0.35,1.63-0.56,2.46-0.15c4.44,2.96,1.63,13.14,0.91,17.6c-0.63,2.94-1.44,5.91-2.96,8.67c-1.4,2.66-6.38,8.42-9.41,4.97
              c-1.64-2.55-0.39-5.8-0.27-8.58c0.36-2.98,0.82-5.95,1.35-8.91L1079.2,611.26L1079.2,611.26z"/>
          </g>
          <g>
            <path className="__sitting-totoro-17" d="M1101.82,621.14c-0.13,1.27-0.21,2.7,0.6,3.68c1.2,1.46,3.69,0.99,4.98-0.38c1.29-1.38,1.73-3.31,2.27-5.12
              c1.34-4.49,14.62-22.78,6-22.87C1105.32,596.34,1102.53,614.23,1101.82,621.14z"/>
            <path d="M1103.38,621.3c-0.1,1.03-0.31,2.15,0.37,2.76c0.62,0.45,1.89,0.13,2.49-0.4c1.4-1.16,1.8-3.65,2.58-5.53
              c1.65-3.47,3.79-6.65,5.56-9.98c1.34-2.79,6.94-11.98,0.15-10.99c-6.86,1.1-9.36,9.2-11.05,15.09c-0.75,2.92-1.28,5.89-1.66,8.89
              L1103.38,621.3L1103.38,621.3z M1101.82,621.14c0.76-7.45,1.83-15.45,6.51-21.58c2.15-2.68,6.49-5.04,9.81-3.06
              c4.23,3.6-4.4,16.45-6.17,20.61c-1.89,3.39-1.69,9.01-6.61,9.94c-3.92,0.73-5.63-2.72-5.1-6.07
              C1100.26,620.98,1101.82,621.14,1101.82,621.14L1101.82,621.14z"/>
          </g>
          <g>
            <path className="__sitting-totoro-17" d="M1133.17,617.75c-6.34,3.86-10.82,10.63-11.88,17.98c0.6,0.53,1.51,0.45,2.29,0.3
              c8.05-1.59,15.63-10.18,14.53-18.66C1136.36,616.7,1134.8,616.94,1133.17,617.75z"/>
            <path d="M1137.07,617.21c-1.38,0.87-3.98,2.35-5.27,3.29c-2.1,1.56-4,3.47-5.5,5.62c-2.03,2.89-3.47,6.23-4.08,9.74l-0.31-0.84
              c0.12,0.15,0.47,0.25,0.96,0.21c1.59-0.18,3.24-0.74,4.73-1.42c6.05-2.96,10.98-9.44,10.37-16.42c0,0,0.09,0.12,0.09,0.12
              C1137.74,617.38,1137.41,617.29,1137.07,617.21L1137.07,617.21z M1133.17,617.75c1.48-0.82,3.56-1.27,5.08-0.41
              c0.36,2.35,0.06,4.79-0.74,7.05c-2.28,6.23-7.87,11.38-14.47,12.61c-0.71,0.15-2.72-0.08-2.68-1.36
              c0.44-3.85,1.79-7.59,3.86-10.91c1.78-2.88,4.2-5.47,6.93-7.49c0,0,1.28-0.87,1.28-0.87L1133.17,617.75L1133.17,617.75z"/>
          </g>
          <g>
            <path className="__sitting-totoro-25" d="M1159.51,189.92c-3.31,1.01-6.59,2.14-9.93,3.05c-7.64,2.08-15.75,3.08-22.58,7.1
              c-1.16,0.69-2.32,1.5-2.98,2.67c-0.66,1.17-0.71,2.8,0.23,3.77c0.82,0.84,2.1,0.99,3.27,1c7.04,0.11,13.83-2.4,20.3-5.17
              c6.23-2.66,12.34-5.6,18.32-8.78c2.51-1.34,5.01-2.73,7.63-3.85c3.36-1.44,6.89-2.43,10.43-3.33c2.47-0.63,6.92-0.84,8.79-2.64
              c-5.16-0.54-11.45,1.11-16.54,2.05C1170.73,186.83,1165.07,188.21,1159.51,189.92z"/>
            <path className="__sitting-totoro-25" d="M1135.53,218.22c3.3,0.96,6.81,0.8,10.25,0.63c11.36-0.55,22.73-1.1,34.09-1.65
              c0.17-0.01,0.37-0.04,0.43-0.2c0.06-0.16-0.26-0.29-0.26-0.12c5.82,0.16,11.65,0.31,17.47,0.47c5.88,0.16,11.84,0.31,17.54-1.13
              c-15.25,0.89-30.55,0.99-45.81,0.29c-3.51-0.16-7.01-0.36-10.52-0.56c-5.06-0.29-10.11-0.58-15.17-0.87
              c-1.78-0.1-3.98-0.6-5.7,0.02C1137.03,215.41,1133.57,217.65,1135.53,218.22z"/>
            <path className="__sitting-totoro-25" d="M1142.99,234.04c19.01,1.9,38.41,1.92,56.76,7.23c9.17,2.65,17.91,6.57,26.62,10.48
              c0.09-1.28-1.3-2.1-2.48-2.6c-23.96-10.05-49.17-17.1-74.87-20.91c-2.55-0.38-8.9-2.22-10.98-0.02
              C1134.88,231.56,1140.56,233.79,1142.99,234.04z"/>
            <path className="__sitting-totoro-25" d="M831.63,195.81c-11.15-2.69-22.53-6.2-33.88-4.52c23.77,7.88,48.21,13.74,72.97,17.51
              c4.01,0.61,8.03,1.17,12.08,1.26c1.26,0.03,5.41-0.26,3.98-2.39c-0.78-1.16-6.19-1.69-7.51-2.01c-5.28-1.28-10.6-2.38-15.93-3.42
              C852.75,200.17,842.11,198.34,831.63,195.81z"/>
            <path className="__sitting-totoro-25" d="M796.28,216.29c19.65,6.07,39.72,10.78,60.01,14.1c4.72,0.77,9.53,1.47,14.27,0.83
              c4.15-0.56,6.92-5.76,1.72-7c-1.97-0.47-4.49-0.08-6.52-0.28c-3.21-0.32-6.42-0.69-9.62-1.09c-12.82-1.59-25.58-3.67-38.42-5.07
              c-3.56-0.39-7.13-0.72-10.7-0.98C803.8,216.58,799.33,217.23,796.28,216.29z"/>
            <path className="__sitting-totoro-25" d="M808.9,243.43c9.04,1.68,18.08,3.35,27.13,5.03c4.37,0.81,8.77,1.62,13.21,1.71
              c1.67,0.03,3.38-0.05,4.93-0.68c1.23-0.5,3.38-1.83,2.32-3.1c-0.73-0.87-5-0.75-6.14-0.89c-4.61-0.55-9.24-0.99-13.87-1.33
              C827.3,243.5,818.09,243.26,808.9,243.43z"/>
          </g>
        </g>
    </svg>
  );
}
